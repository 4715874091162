import React, { useCallback, useMemo, useState } from 'react';
import { string } from 'prop-types';
import {
  DayPickerRangeController,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from 'react-dates';
import classNames from 'classnames';
import {
  convertDateToMoment,
  dateIsAfter,
  addRemoveDaysFromDate,
  START_DATE,
  END_DATE,
  stringifyDateToISO8601,
} from '../../util/dates';

import { IconArrowHead } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { useIntl } from 'react-intl';
import bus, { AuthEventTypes } from '../../modules/bus';
import css from './DateRangeController.module.css';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

// IconArrowHead component might not be defined if exposed directly to the file.
// This component is called before IconArrowHead component in components/index.js
const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

const defaultProps = {
  startDateOffset: undefined,
  endDateOffset: undefined,

  // calendar presentation and interaction related props

  orientation: HORIZONTAL_ORIENTATION,
  verticalHeight: undefined,
  withPortal: false,
  isRTL: false,
  initialVisibleMonth: null,
  // This gets default value at FieldDateRangeController
  firstDayOfWeek: 0,
  numberOfMonths: 1,
  daySize: 38,
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: <PrevIcon />,
  navNext: <NextIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  transitionDuration: 200, // milliseconds between next month changes etc.

  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: day => {
    return (
      <span className={classNames('renderedDay', css.day)} tabIndex="-1">
        {day.format('D')}
      </span>
    );
  },
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => false,

  // This gets default value at FieldDateRangeController
  isOutsideRange: day => false,
  isDayHighlighted: () => {},

  // Internationalization props
  // Multilocale support can be achieved with displayFormat like moment.localeData.longDateFormat('L')
  // https://momentjs.com/
  // displayFormat: 'ddd, MMM D',
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
};

const DateRangeController = props => {
  // Removing Final Form field props: id, name, value, onChange, onFocus, meta, children, render
  const {
    rootClassName,
    className,
    id,
    name,
    value,
    onChange,
    onFocus,
    meta,
    children,
    render,
    timeZone,
    useAvailability,
    monthlyTimeSlots,
    selectedSeats,
    isMultiDay,
    multiDaySessionStartIndex,
    duration,
    onDatesChanged,
    showCalendarFooter,
    popupId,
    ...controllerProps
  } = props;

  const config = useConfiguration();
  const intl = useIntl();

  const startDateFromForm = useMemo(
    () => (value?.startDate ? convertDateToMoment(value.startDate) : null),
    [value]
  );
  const endDateFromForm = useMemo(
    () => (value?.endDate ? convertDateToMoment(value.endDate) : null),
    [value.endDate]
  );

  const [dates, setDates] = useState({
    startDate: startDateFromForm,
    endDate: endDateFromForm,
  });

  const [focusedInput, setFocusedInput] = useState(START_DATE);

  const classes = useMemo(() => classNames(rootClassName || css.inputRoot, className), [
    className,
    rootClassName,
  ]);

  const { startDate: startDateFromState, endDate: endDateFromState } = useMemo(() => dates, [
    dates,
  ]);

  const isStartDateFocused = useMemo(() => focusedInput === START_DATE, [focusedInput]);

  const timeSlots = useMemo(() => {
    if (!monthlyTimeSlots?.timeSlots) return [];

    return monthlyTimeSlots?.timeSlots.filter(slot => slot.availableSeats >= selectedSeats);
  }, [monthlyTimeSlots?.timeSlots, selectedSeats]);

  const availableStartDates = useMemo(
    () =>
      timeSlots.reduce((availableStarts, slot) => {
        const { startsAt } = slot;

        const startAsMoment = convertDateToMoment(startsAt, timeZone);

        // returns 1-7 where 1 is Monday and 7 is Sunday
        const startDoWIndex = startAsMoment.isoWeekday() - 1;

        const dateString = stringifyDateToISO8601(startAsMoment, timeZone);

        const canUseDate = isMultiDay ? startDoWIndex === multiDaySessionStartIndex : true;

        if (canUseDate) {
          availableStarts[dateString] = canUseDate;
        }

        return availableStarts;
      }, {}),
    [isMultiDay, multiDaySessionStartIndex, timeSlots, timeZone]
  );

  const isSelected = useMemo(() => startDateFromState && endDateFromState, [
    endDateFromState,
    startDateFromState,
  ]);

  // Value given by Final Form reflects url params and is valid if both dates are set.
  // If only one date is selected state should be used to get the correct date.
  const startDate = useMemo(() => (isSelected ? startDateFromForm : startDateFromState), [
    isSelected,
    startDateFromForm,
    startDateFromState,
  ]);
  const endDate = useMemo(() => (isSelected ? endDateFromForm : endDateFromState), [
    endDateFromForm,
    endDateFromState,
    isSelected,
  ]);

  const togglePopup = useCallback(() => {
    bus.broadcastEvent(AuthEventTypes.FILTER_POPUP_TOGGLE, popupId);
  }, [popupId]);

  const onApply = useCallback(
    dates => {
      const startDate = dates?.startDate;
      let endDate = dates?.endDate;

      if (isMultiDay && startDate) {
        endDate = convertDateToMoment(addRemoveDaysFromDate(startDate, null, duration - 1));
      } else if (startDate && !endDate) {
        endDate = convertDateToMoment(startDate);
      }

      const start = startDate ? startDate.toDate() : null;
      const end = endDate ? endDate.toDate() : null;

      onDatesChanged?.({ startDate: start, endDate: end });

      onChange({ startDate: start, endDate: end });

      // If we have multi day session, the end date is predefined
      if (startDate && endDate && (isMultiDay || focusedInput === END_DATE)) {
        togglePopup();
      }
    },
    [duration, focusedInput, isMultiDay, onChange, onDatesChanged, togglePopup]
  );

  const onDatesChange = useCallback(
    (values, forcedFocusInput) => {
      const { startDate, endDate } = values;

      if ((!startDate && !endDate) || forcedFocusInput) {
        togglePopup();

        return;
      }

      setDates({ startDate, endDate });

      onApply({ startDate, endDate });
    },
    [onApply, togglePopup]
  );

  const onFocusChange = useCallback(focusedInput => {
    // Force the focusedInput to always be truthy so that dates are always selectable
    setFocusedInput(!focusedInput ? START_DATE : focusedInput);
  }, []);

  const isDayBlocked = useCallback(
    day => {
      if (!timeSlots || timeSlots.length === 0) return false;

      const dateString = stringifyDateToISO8601(day, timeZone);

      if (!isMultiDay || isStartDateFocused) {
        return !availableStartDates[dateString];
      }

      if (isMultiDay) {
        const bookingStart = startDate || addRemoveDaysFromDate(day, timeZone, duration - 1, true);

        const isDayBeforeBookingStart = dateIsAfter(bookingStart, day);
        const isDayAfterBookingEnd = dateIsAfter(day, endDate);

        const startDateStringFromEnd = stringifyDateToISO8601(bookingStart, timeZone);

        return (
          isDayBeforeBookingStart ||
          isDayAfterBookingEnd ||
          !availableStartDates[startDateStringFromEnd]
        );
      }

      return false;
    },
    [
      availableStartDates,
      duration,
      endDate,
      isMultiDay,
      isStartDateFocused,
      startDate,
      timeSlots,
      timeZone,
    ]
  );

  const isOutsideRange = useCallback(
    day => {
      const endOfRange = config.stripe.dayCountAvailableForBooking - 1;

      return (
        !isInclusivelyAfterDay(day, convertDateToMoment()) ||
        !isInclusivelyBeforeDay(day, convertDateToMoment().add(endOfRange, 'days'))
      );
    },
    [config]
  );

  const clearDates = useCallback(() => {
    const emptyDates = { startDate: null, endDate: null };

    onDatesChanged?.(emptyDates);

    setDates(emptyDates);

    onChange(null);
  }, [onChange, onDatesChanged]);

  const renderDateButtons = useCallback(() => {
    if (!showCalendarFooter) return;

    return (
      <div className={css.calendarFooter}>
        <button type="button" onClick={clearDates} className={css.clearButton}>
          {intl.formatMessage({ id: 'FilterForm.clear' })}
        </button>
        <button type="button" onClick={togglePopup} className={css.cancelButton}>
          {intl.formatMessage({ id: 'General.cancel' })}
        </button>
        <button
          type="button"
          onClick={() => {
            onDatesChange(dates, END_DATE);
          }}
          className={css.submitButton}
        >
          {intl.formatMessage({ id: 'General.apply' })}
        </button>
      </div>
    );
  }, [clearDates, dates, intl, onDatesChange, showCalendarFooter, togglePopup]);

  return (
    <div className={classes} tabIndex="-1">
      <DayPickerRangeController
        {...controllerProps}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        isDayBlocked={isDayBlocked}
        isOutsideRange={isOutsideRange}
        renderCalendarInfo={renderDateButtons}
      />
    </div>
  );
};

DateRangeController.defaultProps = {
  rootClassName: null,
  className: null,
  ...defaultProps,
};

DateRangeController.propTypes = {
  rootClassName: string,
  className: string,
};

export default DateRangeController;
