import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { number, shape, string } from 'prop-types';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, IconSearch } from '../../components';
import { parseSearchFormValuesToParams } from '../../util/search';
import { createResourceLocatorString } from '../../util/routes';
import { withViewport } from '../../util/uiHelpers';
import TopbarSearchForm from '../Topbar/TopbarSearchForm/TopbarSearchForm';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import defaultLocations from '../../config/configDefaultLocationSearches';
import bus, { AuthEventTypes } from '../../modules/bus';
import DisciplineSlider from '../DisciplineSlider/DisciplineSlider';

import css from './SectionHero.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const SectionHero = props => {
  const { rootClassName, className, history, viewport, onManageDisableScrolling } = props;

  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();

  const searchFormContainerRef = useRef();
  const searchFormRef = useRef();

  const classes = classNames(rootClassName || css.root, className);

  const isMobileLayout = useMemo(() => viewport.width < MAX_MOBILE_SCREEN_WIDTH, [viewport.width]);

  const handleSubmit = useCallback(
    formValues => {
      const formValuesAsParams = parseSearchFormValuesToParams(formValues);
      const location = defaultLocations[0].predictionPlace;
      const searchParams = { ...location, ...formValuesAsParams };

      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    },
    [history, routeConfiguration]
  );

  const searchFormRefCallback = useCallback(node => (searchFormRef.current = node), []);

  useEffect(() => {
    const handleScroll = () => {
      const { top = 0 } = searchFormContainerRef.current?.getBoundingClientRect() || {};

      // If we are close or after the search form container on hero
      if (top < 0) {
        searchFormRef.current?.classList?.remove(css.collapseTowardsHero);
        searchFormRef.current?.classList?.add(css.collapseTowardsTopbar);
      } else {
        searchFormRef.current?.classList?.remove(css.collapseTowardsTopbar);
        searchFormRef.current?.classList?.add(css.collapseTowardsHero);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div
          ref={node => (searchFormContainerRef.current = node)}
          id="SectionHeroFormContainer"
          className={css.titleAndForm}
        >
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" />
          </h1>
          {isMobileLayout ? (
            <button
              type="button"
              onClick={() => bus.broadcastEvent(AuthEventTypes.EXPAND_TOPBAR)}
              className={classNames(css.expandTopbarOnMobile, css.searchForm)}
            >
              <label>{intl.formatMessage({ id: 'General.findAdventure' })}</label>
              <span>
                <IconSearch />
              </span>
            </button>
          ) : (
            <TopbarSearchForm
              onSubmit={handleSubmit}
              searchFormRef={searchFormRefCallback}
              className={css.searchForm}
              isExpandedByDefault
              isMobileLayout={isMobileLayout}
              onManageDisableScrolling={onManageDisableScrolling}
              formId="SectionHeroSearchForm"
            />
          )}
        </div>
        <DisciplineSlider className={css.disciplines} />
        <div className={css.callToActionArea}>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>

          {/* <div className={css.spacer}></div> */}

          <NamedLink name="SearchPage" className={css.heroButton}>
            <FormattedMessage id="General.browse" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(SectionHero);
