import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Avatar,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconNotification,
  LanguageSelect,
} from '..';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';

import css from './TopbarContent.module.css';

const TopbarContent = props => {
  const {
    className,
    currentUser,
    rootClassName,
    notificationCount,
    messageNotificationCount,
    intl,
    isAuthenticated,
    onLogout,
    currentUserHasListings,
    search,
    currentPage,
    isTopbarSticky,
    isLandingPage,
    isSearchPage,
    authInProgress,
    location,
  } = props;
  const [mounted, setMounted] = useState(false);

  const isAuthenticatedOrJustHydrated = useMemo(() => isAuthenticated || !mounted, [
    isAuthenticated,
    mounted,
  ]);

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  const notificationDot = useMemo(
    () =>
      notificationCount > 0 || messageNotificationCount ? (
        <IconNotification className={css.notificationDot} />
      ) : null,
    [notificationCount, messageNotificationCount]
  );

  const currentPageClass = useCallback(
    page => {
      const isAccountSettingsPage =
        page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
      return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
    },
    [currentPage]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <nav className={classes}>
      <div className={css.logoWrapper}>
        <NamedLink
          className={classNames(css.logoLink, { [css.searchPageLogoLink]: isSearchPage })}
          name="LandingPage"
        >
          <Logo
            format="mobile"
            className={classNames(css.mobileLogo, {
              [css.hideLogo]: isLandingPage && !isTopbarSticky,
            })}
          />
          <Logo
            format="desktop"
            className={classNames(css.logo, {
              [css.desktopLogoOnLanding]: isLandingPage && !isTopbarSticky,
            })}
            alt={intl.formatMessage({ id: 'TopbarContent.logo' })}
          />
        </NamedLink>
      </div>

      {search}

      <div className={css.rightSide}>
        {isAuthenticated ? (
          <NamedLink
            className={classNames(css.button, {
              [css.heroButton]: isLandingPage && !isTopbarSticky,
              [css.hideLink]: currentPage === 'EditListingPage',
            })}
            name="NewListingPage"
          >
            <FormattedMessage id="General.addActivity" />
          </NamedLink>
        ) : (
          <NamedLink className={classNames(css.button, {
            [css.heroButton]: isLandingPage && !isTopbarSticky,
          })} 
          name="SignupPage"
          >
            <FormattedMessage id="BottomNavigation.signup" />
          </NamedLink>
        )}

        <div className={css.langCurrWrapper}>
          <LanguageSelect location={location} />
        </div>

        <Menu>
          <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
            <Avatar
              className={classNames(css.avatar, {
                [css.marketplaceColorAvatar]: isTopbarSticky || !isLandingPage,
              })}
              user={currentUser}
              disableProfileLink
            />
            {notificationDot}
          </MenuLabel>
          <MenuContent className={css.profileMenuContent}>
            {!isAuthenticatedOrJustHydrated ? (
              <>
                <MenuItem key="SignupPage">
                  <NamedLink
                    className={classNames(css.profileSettingsLink, currentPageClass('SignupPage'))}
                    name="SignupPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarContent.signup" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="LoginPage">
                  <NamedLink
                    className={classNames(css.yourListingsLink, currentPageClass('LoginPage'))}
                    name="LoginPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarContent.login" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="HelpCenterBasePage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      css.itemGap,
                      currentPageClass('HelpCenterBasePage')
                    )}
                    name="HelpCenterBasePage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.help" />
                  </NamedLink>
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem key="AccountSettingsPage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('AccountSettingsPage')
                    )}
                    name="AccountSettingsPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarContent.accountSettingsLink" />
                  </NamedLink>
                </MenuItem>

                <MenuItem key="MessagesPage">
                  <NamedLink
                    className={classNames(css.yourListingsLink, currentPageClass('MessagesPage'))}
                    name="MessagesPage"
                  >
                    <span className={css.menuItemBorder} />
                    <span>
                      <FormattedMessage id="TopbarContent.messages" />
                      {!!messageNotificationCount && (
                        <IconNotification className={css.notificationDotDesktopMenu} />
                      )}
                    </span>
                  </NamedLink>
                </MenuItem>

                <MenuItem key="BookingsPage">
                  <NamedLink
                    className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
                    name="InboxPage"
                    params={{ tab: currentUserHasListings ? 'sales' : 'orders', type: 'upcoming' }}
                  >
                    <span className={css.menuItemBorder} />
                    <span>
                      <FormattedMessage id="TopbarContent.bookings" />
                      {notificationCount > 0 && (
                        <IconNotification className={css.notificationDotDesktopMenu} />
                      )}
                    </span>
                  </NamedLink>
                </MenuItem>

                <MenuItem key="ListingsPage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('ManageListingsPage')
                    )}
                    name="ManageListingsPage"
                    params={{ tab: 'published' }}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarContent.listings" />
                  </NamedLink>
                </MenuItem>

                <MenuItem key="HelpCenterBasePage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      css.itemGap,
                      currentPageClass('HelpCenterBasePage')
                    )}
                    name="HelpCenterBasePage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.help" />
                  </NamedLink>
                </MenuItem>

                <MenuItem key="logout">
                  <button
                    type="button"
                    className={classNames(css.logoutButton, css.yourListingsLink)}
                    onClick={onLogout}
                    disabled={authInProgress}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarContent.logout" />
                  </button>
                </MenuItem>
              </>
            )}
            <MenuItem key="addActivity">
              <NamedLink
                className={classNames(css.yourListingsLink, css.boldItem, css.itemGap, {
                  [css.hideLink]: currentPage === 'EditListingPage',
                })}
                name="NewListingPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="General.addActivity" />
              </NamedLink>
            </MenuItem>
          </MenuContent>
        </Menu>
      </div>
    </nav>
  );
};

TopbarContent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  messageNotificationCount: 0,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarContent.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  messageNotificationCount: number,
  intl: intlShape.isRequired,
};

export default TopbarContent;
