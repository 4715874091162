import httpClient from '../httpClient';

const uploadCertificate = (certificate, discipline) => {
  const formData = new FormData();

  formData.append('files', certificate, certificate.name);
  formData.append('discipline', discipline);

  return httpClient.post('users/files', formData);
};

const deleteCertificate = discipline => {
  return httpClient.delete('users/files', {
    data: {
      discipline,
    },
  });
};

/**
 * Our BE returns  { availabilityException } for success
 * or 409 if there is a conflict error with existing exception
 */
const addAvailabilityException = (startsAt, endsAt) => {
  return httpClient.post('users/availability-exceptions', { startsAt, endsAt });
};

const deleteAvailabilityException = exceptionId => {
  return httpClient.delete(`users/availability-exceptions/${exceptionId}`);
};

const getAvailabilityExceptions = params => {
  return httpClient.get('users/availability-exceptions', { params });
};

const addPromoCodes = codes => {
  return httpClient.post('users/promo-codes', { codes });
};

const verifyInstagram = accessToken => {
  return httpClient.post('users/verify-instagram', { accessToken });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uploadCertificate,
  deleteCertificate,
  addAvailabilityException,
  deleteAvailabilityException,
  getAvailabilityExceptions,
  addPromoCodes,
  verifyInstagram,
};
