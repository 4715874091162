import React, { useCallback, useMemo } from 'react';
import { bool, oneOfType, string } from 'prop-types';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  propTypes,
} from '../../util/types';
import { SecondaryButton } from '..';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeListing,
  deleteListing,
  duplicateListing,
  openListing,
} from '../../ducks/OwnListing.duck';
import { createResourceLocatorString } from '../../util/routes';
import {
  createSlug,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
} from '../../util/urlHelpers';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { ensureOwnListing } from '../../util/data';
import { ACTIVITY, DESCRIPTION } from '../../constants/listingWizard';
import confirm from '../../modules/confirm';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import css from './EditListingActionBar.module.css';

const EditListingActionBar = props => {
  const { className, listing, canEdit, hidePublish, intl } = props;
  const { id: listingId } = listing;

  const {
    duplicateInProgress,
    duplicateError,
    closeInProgress,
    closeError,
    openInProgress,
    openError,
    deleteInProgress,
    deleteError,
  } = useSelector(({ OwnListing }) => OwnListing);

  const routeConfiguration = useRouteConfiguration();

  const dispatch = useDispatch();
  const history = useHistory();

  const currentListing = useMemo(() => ensureOwnListing(listing), [listing]);
  const { title: listingTitle, state, metadata } = currentListing.attributes || {};

  const { hasBookings, hasRequestedDeletion } = metadata || {};

  const isClosed = useMemo(() => state === LISTING_STATE_CLOSED, [state]);
  const isDraft = useMemo(() => state === LISTING_STATE_DRAFT, [state]);
  const isPendingApproval = useMemo(() => state === LISTING_STATE_PENDING_APPROVAL, [state]);

  const canPublish = useMemo(() => !isPendingApproval && (isClosed || isDraft), [
    isClosed,
    isDraft,
    isPendingApproval,
  ]);

  const messages = useMemo(
    () => ({
      listingPendingDeletion: intl.formatMessage({ id: 'General.listingPendingDeletion' }),
      listingClosed: intl.formatMessage({ id: 'General.listingClosed' }),
      listingPendingApproval: intl.formatMessage({ id: 'General.listingPendingApproval' }),
      edit: intl.formatMessage({ id: 'General.edit' }),
      duplicate: intl.formatMessage({ id: 'General.duplicate' }),
      publish: intl.formatMessage({ id: 'General.publish' }),
      unpublish: intl.formatMessage({ id: 'General.unpublish' }),
      delete: intl.formatMessage({ id: 'General.delete' }),
      confirmDeleteTitle: intl.formatMessage({ id: 'General.confirmDeleteTitle' }),
      confirmDeleteDesc: intl.formatMessage({ id: 'General.confirmDeleteDesc' }),
      confirmDeleteDescNotAllowed: intl.formatMessage({
        id: 'General.confirmDeleteDescNotAllowed',
      }),
      confirmPublishTitle: intl.formatMessage({ id: 'General.confirmPublishTitle' }),
      confirmPublishDesc: intl.formatMessage({ id: 'General.confirmPublishDesc' }),
      confirmUnpublishTitle: intl.formatMessage({ id: 'General.confirmUnpublishTitle' }),
      confirmUnpublishDesc: intl.formatMessage({ id: 'General.confirmUnpublishDesc' }),
    }),
    [intl]
  );

  // ---------------------------------------- Render blocks ---------------------------------------- //

  const infoMessagesMaybe = useMemo(() => {
    const infoMessages = [];

    hasRequestedDeletion &&
      infoMessages.push(
        <span key="msg_hasRequestedDeletion" className={classNames(css.message, css.deleteColor)}>
          {messages.listingPendingDeletion}
        </span>
      );
    isClosed &&
      infoMessages.push(
        <span key="msg_isClosed" className={classNames(css.message, css.deleteColor)}>
          {messages.listingClosed}
        </span>
      );
    isPendingApproval &&
      infoMessages.push(
        <span key="msg_isPendingApproval" className={classNames(css.message, css.approveColor)}>
          {messages.listingPendingApproval}
        </span>
      );

    return infoMessages;
  }, [hasRequestedDeletion, isClosed, isPendingApproval, messages]);

  const errorMaybe = useMemo(
    () =>
      (duplicateError || closeError || openError || deleteError) && (
        <span className={css.message}>
          <ErrorMessage />
        </span>
      ),
    [closeError, deleteError, duplicateError, openError]
  );

  // ---------------------------------------- Callbacks ---------------------------------------- //

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const showConfirmModal = useCallback(
    actionName => {
      let confirmConfig = {
        showCloseButton: false,
        onManageDisableScrolling,
      };

      switch (actionName) {
        case 'delete':
          confirmConfig = {
            ...confirmConfig,
            title: messages.confirmDeleteTitle,
            messages: hasBookings
              ? [messages.confirmDeleteDescNotAllowed]
              : [messages.confirmDeleteDesc],
            okText: hasBookings ? messages.unpublish : messages.delete,
            isWarning: !hasBookings,
          };

          break;

        case 'publish':
          confirmConfig = {
            ...confirmConfig,
            title: messages.confirmPublishTitle,
            messages: [messages.confirmPublishDesc],
            okText: messages.publish,
            isConfirmation: true,
          };

          break;

        case 'unpublish':
          confirmConfig = {
            ...confirmConfig,
            title: messages.confirmUnpublishTitle,
            messages: [messages.confirmUnpublishDesc],
            okText: messages.unpublish,
          };

          break;

        default:
          break;
      }

      return confirm(confirmConfig);
    },
    [messages, onManageDisableScrolling, hasBookings]
  );

  const redirectToEditListing = useCallback(
    (newListingId, newListingTitle) => {
      const pathParams = {
        slug: createSlug(newListingTitle || listingTitle),
        type:
          newListingId || isDraft ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT,
        id: newListingId || listingId.uuid,
        tab: newListingId ? ACTIVITY : isDraft ? DESCRIPTION : ACTIVITY,
      };

      history.replace(
        createResourceLocatorString('EditListingPage', routeConfiguration, pathParams, {})
      );
    },
    [history, isDraft, listingId, listingTitle, routeConfiguration]
  );

  const handleDuplicateListing = useCallback(
    async () =>
      dispatch(duplicateListing(listingId)).then(res => {
        const {
          id: { uuid: newListingId },
          attributes: { title: newListingTitle },
        } = res.data.data;

        redirectToEditListing(newListingId, newListingTitle);
      }),
    [dispatch, listingId, redirectToEditListing]
  );

  const handleToggleLisitngState = useCallback(async () => {
    if (isDraft) {
      redirectToEditListing();

      return;
    }

    const hasConfirmed = await showConfirmModal(isClosed ? 'publish' : 'unpublish');

    if (!hasConfirmed) return;

    if (isClosed) {
      dispatch(openListing(listingId));
    } else {
      dispatch(closeListing(listingId));
    }
  }, [dispatch, isClosed, isDraft, listingId, redirectToEditListing, showConfirmModal]);

  const handleDeleteListing = useCallback(async () => {
    const hasConfirmed = await showConfirmModal('delete');

    if (!hasConfirmed) return;

    if (hasBookings && !isClosed) {
      dispatch(closeListing(listingId));

      return;
    }

    if (!hasBookings) {
      dispatch(closeListing(listingId));
      dispatch(deleteListing(listingId));
    }
  }, [dispatch, hasBookings, isClosed, listingId, showConfirmModal]);

  // ---------------------------------------- Renders ---------------------------------------- //

  return (
    <div className={classNames(css.actionBar, className)}>
      {infoMessagesMaybe}

      {canEdit && (
        <SecondaryButton
          className={classNames(css.editButton, css.actionBarBtn)}
          onClick={() => redirectToEditListing()}
        >
          {messages.edit}
        </SecondaryButton>
      )}
      <SecondaryButton
        color="black"
        className={css.actionBarBtn}
        inProgress={duplicateInProgress}
        onClick={handleDuplicateListing}
      >
        {messages.duplicate}
      </SecondaryButton>
      {hidePublish && isDraft ? null : (
        <SecondaryButton
          color="black"
          className={classNames(css.actionBarBtn, { [css.actionBarPublishBtn]: canPublish })}
          inProgress={openInProgress || closeInProgress}
          onClick={handleToggleLisitngState}
        >
          {canPublish ? messages.publish : messages.unpublish}
        </SecondaryButton>
      )}
      {!hasRequestedDeletion && (
        <SecondaryButton
          className={classNames(css.actionBarBtn, css.actionBarDeleteBtn)}
          inProgress={deleteInProgress}
          onClick={handleDeleteListing}
        >
          {messages.delete}
        </SecondaryButton>
      )}

      {errorMaybe}
    </div>
  );
};

EditListingActionBar.defaultProps = {
  className: null,
  canEdit: true,
};

EditListingActionBar.propTypes = {
  className: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  canEdit: bool,
};

EditListingActionBar.displayName = 'EditListingActionBar';

export default injectIntl(EditListingActionBar);
