import { getLocaleFromUrl } from '../constants/locales';
import { setCookie } from '../util/cookieParser';

// ================ Action types ================ //

export const DISABLE_SCROLLING = 'app/ui/DISABLE_SCROLLING';

const CHANGE_INTERFACE_LANG = 'app/ui/CHANGE_INTERFACE_LANG';

// ================ Reducer ================ //

const initialState = {
  disableScrollRequests: [],
  // field is initialized from server when using SSR
  interfaceLang: getLocaleFromUrl(),
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case DISABLE_SCROLLING: {
      const { componentId, disableScrolling } = payload;
      const disableScrollRequests = state.disableScrollRequests;
      const componentIdExists = disableScrollRequests.find(c => c.componentId === componentId);

      if (componentIdExists) {
        const disableScrollRequestArray = disableScrollRequests.map(c => {
          return c.componentId === componentId ? { ...c, disableScrolling } : c;
        });
        return { ...state, disableScrollRequests: [...disableScrollRequestArray] };
      }

      const disableScrollRequestArray = [
        ...disableScrollRequests,
        { componentId, disableScrolling },
      ];
      return {
        ...state,
        disableScrollRequests: disableScrollRequestArray,
      };
    }

    case CHANGE_INTERFACE_LANG:
      setCookie('language', payload);

      return { ...state, interfaceLang: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const manageDisableScrolling = (componentId, disableScrolling) => ({
  type: DISABLE_SCROLLING,
  payload: { componentId, disableScrolling },
});

const changeLang = lang => ({ type: CHANGE_INTERFACE_LANG, payload: lang });

// ================ Selectors ================ //

export const isScrollingDisabled = state => {
  const { disableScrollRequests } = state.ui;
  return disableScrollRequests.some(r => r.disableScrolling);
};

export const changeInterfaceLang = lang => (dispatch, getState, sdk) => {
  dispatch(changeLang(lang));

  const { currentUser } = getState().user;

  if (!currentUser || !lang) return Promise.resolve(true);

  return sdk.currentUser
    .updateProfile({ publicData: { language: lang.split('-')[0] } })
    .then(() => {})
    .catch(() => {});
};
