import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

const { UUID } = sdkTypes;

const REVIEWS_PAGE_SIZE = 10;
const LISTINGS_PAGE_SIZE = 2;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const QUERY_REVIEWS_COUNT_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_COUNT_REQUEST';
export const QUERY_REVIEWS_COUNT_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_COUNT_SUCCESS';
export const QUERY_REVIEWS_COUNT_ERROR = 'app/ProfilePage/QUERY_REVIEWS_COUNT_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userShowError: null,

  userListingRefs: [],
  listingsPagination: {},
  listingsQueryParams: {},
  queryListingsInProgress: false,
  queryListingsError: null,

  reviews: [],
  reviewsQueryParams: {},
  reviewsPagination: {},
  queryReviewsError: null,
  queryReviewsInProgress: false,

  totalHostReviews: 0,
  totalGuestsReviews: 0,
  queryReviewsCountError: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        queryListingsInProgress: true,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        queryListingsInProgress: false,
        userListingRefs:
          payload.pagination.page > 1
            ? [...state.userListingRefs, ...payload.listingRefs]
            : payload.listingRefs,
        listingsQueryParams: payload.queryParams,
        listingsPagination: payload.pagination,
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        userListingRefs: [],
        queryListingsInProgress: false,
        queryListingsError: payload,
      };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsInProgress: true, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return {
        ...state,
        queryReviewsInProgress: false,
        reviews:
          payload.pagination.page > 1 ? [...state.reviews, ...payload.reviews] : payload.reviews,
        reviewsQueryParams: payload.queryParams,
        reviewsPagination: payload.pagination,
      };
    case QUERY_REVIEWS_ERROR:
      return { ...state, queryReviewsInProgress: false, reviews: [], queryReviewsError: payload };
    case QUERY_REVIEWS_COUNT_REQUEST:
      return { ...state, queryReviewsCountError: null };
    case QUERY_REVIEWS_COUNT_SUCCESS:
      return {
        ...state,
        totalHostReviews:
          payload.type === 'ofCustomer' ? payload.totalItems : state.totalHostReviews,
        totalGuestsReviews:
          payload.type === 'ofProvider' ? payload.totalItems : state.totalGuestsReviews,
      };
    case QUERY_REVIEWS_COUNT_ERROR:
      return {
        ...state,
        totalHostReviews: 0,
        totalGuestsReviews: 0,
        queryReviewsCountError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = response => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: response,
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsCountRequest = () => ({
  type: QUERY_REVIEWS_COUNT_REQUEST,
});

export const queryReviewsCountSuccess = response => ({
  type: QUERY_REVIEWS_COUNT_SUCCESS,
  payload: response,
});

export const queryReviewsCountError = e => ({
  type: QUERY_REVIEWS_COUNT_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = response => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: response,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = params => (dispatch, getState, sdk) => {
  const { userId, page = 1 } = params;

  dispatch(queryListingsRequest(userId));
  return sdk.listings
    .query({
      author_id: userId,
      per_page: LISTINGS_PAGE_SIZE,
      page,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      const pagination = response.data.meta;
      const queryParams = { userId };
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess({ listingRefs, pagination, queryParams }));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = params => (dispatch, getState, sdk) => {
  const { userId, page = 1, type = 'ofCustomer' } = params;
  dispatch(queryReviewsRequest());

  sdk.reviews
    .query({
      subject_id: userId,
      type,
      per_page: REVIEWS_PAGE_SIZE,
      page,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const pagination = response.data.meta;
      const queryParams = { type, ...params };
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess({ reviews, pagination, queryParams }));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const queryUserReviewsCount = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      type: 'ofCustomer',
      per_page: 1,
      state: 'public',
    })
    .then(response => {
      const pagination = response.data.meta;
      const { totalItems } = pagination;
      dispatch(queryReviewsCountSuccess({ type: 'ofCustomer', totalItems }));
    })
    .catch(e => dispatch(queryReviewsCountError(e)));

  sdk.reviews
    .query({
      subject_id: userId,
      type: 'ofProvider',
      per_page: 1,
      state: 'public',
    })
    .then(response => {
      const pagination = response.data.meta;
      const { totalItems } = pagination;
      dispatch(queryReviewsCountSuccess({ type: 'ofProvider', totalItems }));
    })
    .catch(e => dispatch(queryReviewsCountError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = params => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserReviewsCount(userId)),
    dispatch(queryUserListings({ userId })),
    dispatch(queryUserReviews({ userId })),
  ]);
};
