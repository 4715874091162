import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToTz, resetToStartOfDay } from '../../util/dates';
import { parse } from '../../util/urlHelpers';
import { LISTING_STATE_PUBLISHED } from '../../util/types';
import { parseSelectFilterOptions } from '../../util/search';
import { parseListingToSharetribe } from '../../util/sharetribeParser';
import api from '../../api';
import { getLocationQuery } from '../../util/maps';
import defaultConfig from '../../config/configDefault';
import unionWith from 'lodash/unionWith';
import isEqual from 'lodash/isEqual';

const RESULT_PAGE_SIZE = 50;

const AVAILABLE_SELECT_MULTIPLE_FILTER_QUERY_PARAMS = [
  'pub_activity',
  'pub_discipline',
  'pub_type',
  'pub_ability',
  'pub_languages',
  'pub_groupType',
];

const checkShouldResetListings = (searchParams, stateSearchParams) => {
  const { bounds: stateBounds, page: statePage, ...restOfStateSearchParams } =
    stateSearchParams || {};

  const { bounds, page, ...restOfSearchParams } = searchParams;

  return !isEqual(restOfStateSearchParams, restOfSearchParams);
};

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  listings: [],
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  suggestedListings: [],
};

export default function searchPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS: {
      const { shouldResetListings, listings, pagination, suggestedListings } = payload;

      const listingsFinal = shouldResetListings
        ? listings
        : unionWith(state.listings, listings, (l1, l2) => l1.id.uuid === l2.id.uuid);

      return {
        ...state,
        listings: listingsFinal,
        pagination,
        searchInProgress: false,
        suggestedListings,
      };
    }
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = (
  listings,
  pagination,
  shouldResetListings,
  suggestedListings
) => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { listings, pagination, shouldResetListings, suggestedListings },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  const { searchParams: stateSearchParams } = getState().SearchPage;

  const shouldResetListings = checkShouldResetListings(searchParams, stateSearchParams);

  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(defaultConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? { from: inSubunits(values[0]), to: inSubunits(values[1]) + 1 }
      : {};
  };

  const availabilityParams = (datesParam, minDurationParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const startDate = hasDateValues ? dateValues[0] : null;
    const endDate = hasDateValues ? dateValues[1] : null;

    const timeZone = 'Etc/UTC';

    return hasDateValues
      ? {
          startDate: formatDateStringToTz(startDate, timeZone),
          endDate: resetToStartOfDay(endDate, null, 1),
        }
      : {};
  };

  const { bounds, dates, minDuration, seats, price, page, sort, ...rest } = searchParams;

  // Listing state
  const publishedListings = { 'attributes.state': LISTING_STATE_PUBLISHED };
  // Bounds (geoLocation of the listing)
  const geoLocationMaybe = getLocationQuery(bounds);
  // Dates
  const datesMaybe = availabilityParams(dates, minDuration);
  // Filters
  const priceObjMaybe = priceSearchParams(price);
  const priceMaybe = price
    ? { 'attributes.price.amount': { $gte: priceObjMaybe.from, $lte: priceObjMaybe.to } }
    : {};
  const peopleAtMostMaybe = rest.pub_people
    ? {
        'attributes.publicData.peopleAtMost': {
          $gte: +parseSelectFilterOptions(rest.pub_people)[0],
        },
      }
    : {};
  let people = [];
  if (rest.adults) people.push('adults');
  if (rest.children) people.push('children');
  const peopleMaybe =
    people.length === 1 ? { 'attributes.publicData.people': { $in: people } } : {};
  let selectMultipleFiltersMaybe = {};
  AVAILABLE_SELECT_MULTIPLE_FILTER_QUERY_PARAMS.forEach(f => {
    const key = rest[f];
    const sufix = f?.split('_')[1];
    if (key && sufix) {
      selectMultipleFiltersMaybe[`attributes.publicData.${sufix}`] = {
        $in: parseSelectFilterOptions(rest[f]),
      };
    }
  });
  const providerMaybe = rest.pub_provider
    ? {
        'attributes.metadata.userType': {
          $in: parseSelectFilterOptions(rest.pub_provider),
        },
      }
    : {};
  // Sort
  const sortMaybe = sort ? { $sort: sort } : {};

  const newParams = {
    ...publishedListings,
    ...geoLocationMaybe,
    ...datesMaybe,
    ...priceMaybe,
    ...peopleAtMostMaybe,
    ...selectMultipleFiltersMaybe,
    ...providerMaybe,
    ...sortMaybe,
    ...peopleMaybe,
    $limit: RESULT_PAGE_SIZE,
    $page: page,
  };

  return api.listings
    .fetchListings(newParams)
    .then(res => {
      const { items, suggestions, ...paginationProps } = res.data;
      const listings = items.map(listing => parseListingToSharetribe(listing, listing.author));
      const pagination = {
        ...paginationProps,
        perPage: RESULT_PAGE_SIZE,
        page: paginationProps.currentPage,
      };

      const { unavailableSuggestions, extendedLocationSuggestions, otherActivitySuggestions } =
        suggestions || {};
      const suggestedListings = [
        ...(unavailableSuggestions?.items.length
          ? unavailableSuggestions?.items?.map(listing =>
              parseListingToSharetribe(listing, listing.author)
            )
          : []),
        ...(extendedLocationSuggestions?.items.length
          ? extendedLocationSuggestions?.items?.map(listing =>
              parseListingToSharetribe(listing, listing.author)
            )
          : []),
        ...(otherActivitySuggestions?.items.length
          ? otherActivitySuggestions?.items?.map(listing =>
              parseListingToSharetribe(listing, listing.author)
            )
          : []),
      ].filter(Boolean);

      dispatch(searchListingsSuccess(listings, pagination, shouldResetListings, suggestedListings));
    })
    .catch(e => {
      dispatch(searchListingsError(e));
      throw e;
    });
};

export const loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;

  return searchListings({ ...rest, page, perPage: RESULT_PAGE_SIZE });
};
