import React, { useCallback, useMemo, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import classNames from 'classnames';
import { arrayOf, bool, func, instanceOf, object, shape, string } from 'prop-types';
import {
  convertDateToMoment,
  dateIsAfter,
  daysBetween,
  resetToStartOfDay,
  stringifyDateToISO8601,
} from '../../util/dates';
import { intlShape, injectIntl } from '../../util/reactIntl';
import CalendarToolbar from './CalendarToolbar';
import CalendarDateCellWrapper from './CalendarDateCellWrapper';
import CalendarEvent from './CalendarEvent';
import { IconSpinner, InfoTooltip } from '../../components';
import { checkIfDatesOverlap, createEventFromBooking } from '../../util/sessions';
import unionWith from 'lodash/unionWith';

import css from './BigCalendar.module.css';

const CALENDAR_STYLE = { minHeight: 500 };

const VIEWS = [Views.MONTH, Views.WEEK, Views.DAY];

const TODAY = new Date();

const FORMATS = {
  weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
  timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'HH:mm', culture),
};

const splitMultiDayEvent = event => {
  const {
    resource: { bookings },
  } = event;

  const splitEvents = bookings.reduce((splitEvents, booking) => {
    const { startsAt, endsAt, timezone, isOvernight, ...restOfBooking } = booking;

    const startDate = convertDateToMoment(startsAt, timezone);
    const endDate = convertDateToMoment(endsAt, timezone);

    const bookedDays = daysBetween(startDate, endDate, true);

    const isSingleDayBooking = bookedDays <= 1;

    // If the booking is overnight, we don't want to split it
    // because the booking should take the full range from the
    // start till the end of the booking
    if (isOvernight) {
      splitEvents.push(event);
    }

    // If booking duration is <= a day
    // it means its a single day booking
    // and we shouldn't split it
    if (isSingleDayBooking) {
      splitEvents.push(event);

      // If its multi day and not overnight
      // we should split it so we don't book
      // space that isn't actually booked
    } else if (!isOvernight) {
      let newEvents = [];

      const newBooking = {
        ...restOfBooking,
        timezone,
        isOvernight,
      };

      const date = convertDateToMoment(startDate, timezone);

      while (date.isSameOrBefore(endDate, 'day')) {
        newBooking._id += '-' + date.date();
        newBooking.startsAt = date.toISOString();
        newBooking.endsAt = date
          .clone()
          .hours(endDate.hours())
          .minutes(endDate.minutes())
          .toISOString();

        const newEvent = createEventFromBooking(newBooking);

        newEvents.push(newEvent);

        date.add(1, 'day');
      }

      splitEvents.push(...newEvents);
    }

    return splitEvents;
  }, []);

  return splitEvents;
};

const getEndAccessor = event => {
  const endMoment = convertDateToMoment(event.end);

  const endTime = endMoment.format('HH:mm');

  const isMidnight = endTime === '00:00';

  if (isMidnight) {
    endMoment.add(1, 'day');
  }

  return endMoment.toDate();
};

const EmptyComponent = () => null;

const BigCalendar = props => {
  const {
    className,
    intl,
    events: rawEvents,
    onRangeChange,
    onViewChange,
    onSelectEvent,
    selectedEvent,
    fetchEventsInProgress,
    fetchEventsError,
    availableDays,
  } = props;

  const [selectedView, setSelectedView] = useState(Views.MONTH);

  const localizer = useMemo(() => momentLocalizer(moment), []);

  const isMonthView = useMemo(() => selectedView === Views.MONTH, [selectedView]);

  const messages = useMemo(
    () => ({
      calendar: intl.formatMessage({ id: 'General.calendar' }),
      calendarTooltip: intl.formatMessage({ id: 'General.calendarTooltip' }),
      bookingPending: intl.formatMessage({ id: 'General.bookingPending' }),
      bookingConfirmed: intl.formatMessage({ id: 'General.bookingConfirmed' }),
      fetchError: intl.formatMessage({ id: 'General.error' }),
    }),
    [intl]
  );

  const calendarTooltip = useMemo(
    () => <InfoTooltip id="BigCalendar.calendarTooltip">{messages.calendarTooltip}</InfoTooltip>,
    [messages.calendarTooltip]
  );

  const calendarMessages = useMemo(
    () => ({
      date: intl.formatMessage({ id: 'General.date' }),
      time: intl.formatMessage({ id: 'General.time' }),
      week: intl.formatMessage({ id: 'General.week' }),
      day: intl.formatMessage({ id: 'General.day' }),
      month: intl.formatMessage({ id: 'General.month' }),
    }),
    [intl]
  );

  // We want to merge overlapping bookings/sessions
  // so we can follow the design for the calendar.
  // We do this by extending the event start/end dates
  // and pushing all the events that are inside that range
  // inside the resource.bookings array
  const events = useMemo(
    () =>
      // We first split multi day bookings
      rawEvents
        .reduce((splitEvents, event) => [...splitEvents, ...splitMultiDayEvent(event)], [])
        .reduce((combinedEvents, event) => {
          const { start: eventStart, end: eventEnd, resource } = event;

          const start = isMonthView ? resetToStartOfDay(eventStart, null) : eventStart;
          const end = isMonthView
            ? convertDateToMoment(eventEnd)
                .endOf('day')
                .toDate()
            : eventEnd;

          const mergeableEvent = combinedEvents.find(e =>
            checkIfDatesOverlap(e.start, e.end, start, end)
          );

          if (mergeableEvent) {
            // Extend the start/end dates
            mergeableEvent.start = dateIsAfter(mergeableEvent.start, start)
              ? start
              : mergeableEvent.start;
            mergeableEvent.end = dateIsAfter(mergeableEvent.end, end) ? mergeableEvent.end : end;

            mergeableEvent.resource = {
              ...mergeableEvent.resource,
              bookings: isMonthView
                ? unionWith(
                    mergeableEvent.resource.bookings,
                    resource.bookings,
                    (b1, b2) => b1.transactionId === b2.transactionId
                  )
                : [...mergeableEvent.resource.bookings, ...resource.bookings],
            };

            return combinedEvents;
          }

          combinedEvents.push(event);

          return combinedEvents;
        }, []),
    [isMonthView, rawEvents]
  );

  const datesWithEvents = useMemo(
    () =>
      events.reduce((dates, event) => {
        const { start, end } = event;

        let date = convertDateToMoment(start);

        while (date.isSameOrBefore(end, 'day')) {
          const dateString = stringifyDateToISO8601(date);

          dates[dateString] = true;

          date.add(1, 'day');
        }

        return dates;
      }, {}),
    [events]
  );

  const components = useMemo(
    () => ({
      toolbar: CalendarToolbar,
      event: CalendarEvent,
      month: {
        header: EmptyComponent,
        dateHeader: props => (
          <CalendarDateCellWrapper
            {...props}
            datesWithEvents={datesWithEvents}
            availableDays={availableDays}
          />
        ),
      },
      week: { header: EmptyComponent },
      day: {
        header: EmptyComponent,
        event: props => <CalendarEvent {...props} showTitle />,
      },
    }),
    [availableDays, datesWithEvents]
  );

  const handleViewChange = useCallback(
    newView => {
      setSelectedView(newView);

      onViewChange?.(newView);
    },
    [onViewChange]
  );

  const handleDayPropGetter = useCallback(
    date => {
      const dateString = stringifyDateToISO8601(date);

      const isDateBeforeToday = dateIsAfter(TODAY, [date, 'day'], false);

      const styles = {};

      if (isDateBeforeToday) {
        styles.className = css.calendarOutOfBoundsDays;
      } else if (isMonthView && !availableDays[dateString]) {
        styles.className = css.calendarNoSessionDays;
      }

      return styles;
    },
    [availableDays, isMonthView]
  );

  const handleEventPropGetter = useCallback(
    event => {
      const { resource } = event || {};

      const { bookings } = resource || {};

      let isBookingConfirmed = false;
      let isBookingPending = false;

      bookings.forEach(booking => {
        const { status } = booking;

        isBookingConfirmed = isBookingConfirmed || status === 'ACCEPTED';
        isBookingPending = isBookingPending || status === 'PENDING';
      });

      const styles = {
        className: classNames(css.booking, {
          [css.bookingPending]: isBookingPending,
          [css.bookingConfirmed]: isBookingConfirmed,
          [css.bookingBothStatesHorizontal]: isMonthView && isBookingPending && isBookingConfirmed,
          [css.bookingBothStatesVertical]: !isMonthView && isBookingPending && isBookingConfirmed,
        }),
      };

      return styles;
    },
    [isMonthView]
  );

  const handleSlotPropGetter = useCallback(
    date => {
      const dateString = stringifyDateToISO8601(date);

      const h = date.getHours();
      const m = date.getMinutes();

      const hours = h < 10 ? `0${h}` : h;
      const minutes = m < 10 ? `0${m}` : m;

      const timeString = `${hours}:${minutes}`;

      if (!availableDays[dateString] || !availableDays[dateString]?.[timeString]) {
        return { className: css.calendarNoSessionDays };
      }
    },
    [availableDays]
  );

  return (
    <div className={classNames(css.root, className)}>
      <h3 className={css.title}>
        {messages.calendar} {calendarTooltip}
      </h3>

      {!fetchEventsError ? (
        <div
          className={classNames(css.calendar, {
            [css.withLeftToolbar]: isMonthView,
            [css.weekView]: selectedView === Views.WEEK,
            [css.dayView]: selectedView === Views.DAY,
          })}
        >
          <Calendar
            style={CALENDAR_STYLE}
            endAccessor={getEndAccessor}
            timeslots={1}
            localizer={localizer}
            formats={FORMATS}
            messages={calendarMessages}
            events={events}
            components={components}
            views={VIEWS}
            view={selectedView}
            onView={handleViewChange}
            dayPropGetter={handleDayPropGetter}
            eventPropGetter={handleEventPropGetter}
            onRangeChange={onRangeChange}
            onSelectEvent={onSelectEvent}
            drilldownView={null}
            showMultiDayTimes
            selectable={false}
            selected={selectedEvent}
            slotPropGetter={handleSlotPropGetter}
          />
        </div>
      ) : (
        <p className={css.error}>{messages.fetchError} </p>
      )}

      {fetchEventsInProgress ? (
        <div className={css.spinnerOverlay}>
          <IconSpinner />
        </div>
      ) : null}

      <div className={css.legend}>
        <div className={css.status}>
          <span className={classNames(css.statusCircle, css.bookingPending)} />
          {messages.bookingPending}
        </div>
        <div className={css.status}>
          <span className={classNames(css.statusCircle, css.bookingConfirmed)} />
          {messages.bookingConfirmed}
        </div>
      </div>
    </div>
  );
};

BigCalendar.defaultProps = {
  events: [],
};

BigCalendar.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  events: arrayOf(
    shape({
      title: string,
      start: instanceOf(Date).isRequired,
      end: instanceOf(Date).isRequired,
      resource: object,
    }).isRequired
  ),

  fetchEventsInProgress: bool,
  fetchEventsError: object,

  availableDays: object.isRequired,
  selectedEvent: object,

  onSelectEvent: func,
  onRangeChange: func.isRequired,
  onViewChange: func,
};

export default injectIntl(BigCalendar);
