import { LISTING_TYPE } from '../constants/listingAttributes';
import { USER_TYPE } from '../constants/userAttributes';
import { getDefaultTimeZoneOnBrowser } from './dates';

export const getUserType = user => {
  const { attributes } = user || {};

  const { profile } = attributes || {};

  const { publicData, metadata } = profile || {};

  const { isCompany } = publicData || {};

  const { userType } = metadata || {};

  if (isCompany) return USER_TYPE.BUSINESS;

  return userType || USER_TYPE.USER;
};

export const getDefaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

export const DEFAULT_AVAILABILITY_PLAN = {
  type: 'availability-plan/time',
  timezone: getDefaultTimeZone(),
  entries: [
    { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1000000 },
    { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1000000 },
  ],
};

export const createListingTitle = (intl, titleData) => {
  const { discipline, ability, type, duration, groupType } = titleData;
 
  let title = '';

  if (discipline) {
    title += ' ' + intl.formatMessage({ id: `General.${discipline}` }).toLowerCase();
  }

  if (type === LISTING_TYPE.FREE) {
    title += ' ' + intl.formatMessage({ id: `General.freeSharing` }).toLowerCase();
  } else if (type) {
    title += ' ' + intl.formatMessage({ id: `General.${type}` }).toLowerCase();
  }

  title += ' -';

  if (ability) {
    title += ' ' + intl.formatMessage({ id: `General.${ability}Selected` }).toLowerCase();
  }
  
  return title;
};

export const getListingDescription = description => {
  if (!description) return '';

  if (typeof description === 'string') return description;

  const { english, italian, french, german } = description || {};

  return english || italian || french || german;
};
