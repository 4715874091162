import React, { useCallback, useMemo } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import {
  calculateQuantityFromHours,
  convertDateToMoment,
  dateIsAfter,
  resetToStartOfDay,
} from '../../../util/dates';
import { propTypes } from '../../../util/types';
import {
  AvailabilityDatesPicker,
  FieldNumberIncrementerInput,
  FieldSessionSelector,
  Form,
  IconSpinner,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';
import EstimatedBreakdownMaybe, { estimatedTransaction } from './EstimatedBreakdownMaybe';
import { withViewport } from '../../../util/uiHelpers';
import { safeSumTwoIntegers } from '../../../util/currency';
import { checkIfDatesOverlap } from '../../../util/sessions';
import { getUserType } from '../../../util/listingHelpers';
import { USER_TYPE } from '../../../constants/userAttributes';
import { useSelector } from 'react-redux';

import css from './BookingTimeForm.module.css';

const MOBILE_BREAKPOINT = 768;

const getMaxSeatsInTS = (timeSlots = []) =>
  timeSlots.reduce((maxSeats, slot) => Math.max(maxSeats, slot.availableSeats), 0);

const convertTimeStringToHours = timeString => {
  const [hoursString, minutesString] = timeString.split(':');

  const hours = Number.parseInt(hoursString, 10);
  const minutesAsHours = Number.parseInt(minutesString, 10) / 60;

  return hours + minutesAsHours;
};

const getHourDifferenceFromTimes = (startTime, endTime) => {
  return convertTimeStringToHours(endTime) - convertTimeStringToHours(startTime);
};

const BookingTimeFormComponent = props => {
  const {
    intl,
    rootClassName,
    className,
    submitButtonWrapperClassName,
    price: unitPrice,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onFetchTransactionLineItems,
    listingId,
    isOwnListing,
    timeZone,
    endDatePlaceholder,
    startDatePlaceholder,
    unitType,
    viewport,
    onManageDisableScrolling,
    canShowChildren,
    canShowAdults,
    isMultiDay,
    multiDaySessionStartIndex,
    duration,
    onContactUser,
    sessionsDiscount,
    seasonalCharge,
    additionalPersonCharge,
    isFreeListing,
    onSubmit,
    listingAuthor,
    ...rest
  } = props;

  const { currentUser } = useSelector(state => state.user);

  const classes = classNames(rootClassName || css.root, className);

  const isMobileLayout = useMemo(() => viewport.width <= MOBILE_BREAKPOINT, [viewport.width]);

  const messages = useMemo(
    () => ({
      adults: intl.formatMessage({ id: 'General.adults' }),
      age: intl.formatMessage({ id: 'General.age18' }),
      children: intl.formatMessage({ id: 'General.children' }),
    }),
    [intl]
  );

  // Get the maximum possible seats that the user can pick from all of the timeslots
  const maxPossibleSeats = useMemo(() => getMaxSeatsInTS(monthlyTimeSlots?.timeSlots), [
    monthlyTimeSlots?.timeSlots,
  ]);

  const isListingAuthorBusiness = getUserType(listingAuthor) === USER_TYPE.BUSINESS;

  const getSessionsForSelectedDates = useCallback(
    (startDate, endDate) => {
      if (!monthlyTimeSlots?.timeSlots || !startDate || !endDate) return [];

      const start = resetToStartOfDay(startDate, timeZone);

      const end = resetToStartOfDay(endDate, timeZone, 1);

      const sessionsForDates = monthlyTimeSlots.timeSlots.reduce((sessions, slot) => {
        const { _id, price, startsAt, endsAt, startTime, endTime, isOvernight } = slot;

        const priceAmount = price?.amount || 0;

        const startDateInTz = convertDateToMoment(startsAt, timeZone);

        const isTSAfterBookingStart = dateIsAfter(startDateInTz, start);
        const isTSBeforeBookingEnd = dateIsAfter(end, convertDateToMoment(endsAt, timeZone));

        if (isTSAfterBookingStart && isTSBeforeBookingEnd) {
          const newSession = {
            key: _id,
            value: {
              _id,
              sessionIds: [_id],
              price: priceAmount,
              startTime,
              endTime,
              startsAt,
              endsAt,
              isOvernight,
            },
          };

          sessions.push(newSession);
        }

        return sessions;
      }, []);

      const highestPriceSession = sessionsForDates.reduce(
        (highest, session) => {
          const { startsAt, endsAt, startTime, endTime, price, isOvernight } = session.value;

          const hoursFromTimes = isOvernight
            ? calculateQuantityFromHours(startsAt, endsAt)
            : getHourDifferenceFromTimes(startTime, endTime);

          const hourlyPrice = price / hoursFromTimes;

          if (highest.hourlyPrice < hourlyPrice) {
            highest.sessionPrice = price;
            highest.hourlyPrice = hourlyPrice;
          }

          return highest;
        },
        { sessionPrice: 0, hourlyPrice: 0 }
      );

      return sessionsForDates.map(({ key, value }) => {
        const { startsAt, endsAt, startTime, endTime, price, isOvernight } = value;

        const hoursFromTimes = isOvernight
          ? calculateQuantityFromHours(startsAt, endsAt)
          : getHourDifferenceFromTimes(startTime, endTime);

        const hourlyPrice = price / hoursFromTimes;

        const valueWithSavings = { ...value };

        // If the current session costs the same as the highest one
        // we don't save anything on that session so we don't add 'savings'
        if (hourlyPrice !== highestPriceSession.hourlyPrice) {
          const savings = (1 - hourlyPrice / (highestPriceSession.hourlyPrice || 1)) * 100;

          valueWithSavings.savings = `-${Number.parseInt(savings, 10)}%`;
        }

        return { key, value: JSON.stringify(valueWithSavings) };
      });
    },
    [monthlyTimeSlots, timeZone]
  );

  const resetDatesAndSessions = useCallback(formApi => {
    formApi.batch(() => {
      formApi.change('dates');
      formApi.change('sessions', []);
    });
  }, []);

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  const handleOnChange = useCallback(
    formValues => {
      const { dates, sessions = [], adults = 1, children = 0 } = formValues.values;

      const { startDate, endDate } = dates || {};

      const seats = safeSumTwoIntegers(adults, children);

      if (startDate && endDate && seats && sessions.length !== 0) {
        onFetchTransactionLineItems({
          bookingData: { startDate, endDate, seats, adults, children, sessions },
          listingId,
          isOwnListing,
          seats,
        });
      }
    },
    [isOwnListing, listingId, onFetchTransactionLineItems]
  );

  const areListingSessionsOverlapping = useCallback(
    sessions => {
      if (isListingAuthorBusiness || !sessions || sessions.length === 1) return false;

      for (let i = 0; i < sessions.length; i++) {
        const { startsAt: startsAt1, endsAt: endsAt1 } = JSON.parse(sessions[i]);

        for (let j = i + 1; j < sessions.length; j++) {
          const { startsAt: startsAt2, endsAt: endsAt2 } = JSON.parse(sessions[j]);

          if (
            checkIfDatesOverlap(
              new Date(startsAt1),
              new Date(endsAt1),
              new Date(startsAt2),
              new Date(endsAt2)
            )
          )
            return true;
        }
      }

      return false;
    },
    [isListingAuthorBusiness]
  );

  const handleSubmit = useCallback(
    values => {
      // We extract the 'dates' because we don't use them
      // because they are just the date picker range
      const { dates, sessions, ...restOfValues } = values;

      const { startDate, endDate } = sessions.reduce((dates, session) => {
        const { startsAt, endsAt } = JSON.parse(session);

        const sessionStart = convertDateToMoment(startsAt, timeZone);
        const sessionEnd = convertDateToMoment(endsAt, timeZone);

        dates.startDate =
          !dates.startDate || dateIsAfter(dates.startDate, sessionStart)
            ? sessionStart
            : dates.startDate;
        dates.endDate =
          !dates.endDate || dateIsAfter(sessionEnd, dates.endDate) ? sessionEnd : dates.endDate;

        return dates;
      }, {});

      const newValues = {
        ...restOfValues,
        sessions,
        dates: {
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
        },
      };

      onSubmit(newValues);
    },
    [onSubmit, timeZone]
  );

  if (!unitPrice) {
    return (
      <div className={classes}>
        <p className={css.error}>
          <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
        </p>
      </div>
    );
  }

  return (
    <FinalForm
      {...rest}
      onSubmit={handleSubmit}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const { handleSubmit, values, form } = fieldRenderProps;

        const { dates, children = 0, adults = 1, sessions = [] } = values;

        const { startDate, endDate } = dates || {};

        const hasSelectedSessions = sessions.length !== 0;

        const maxPossibleAdults = canShowChildren
          ? Math.max(maxPossibleSeats - children, 1)
          : maxPossibleSeats;
        const maxPossibleChildren = canShowAdults
          ? Math.max(maxPossibleSeats - adults)
          : maxPossibleSeats;

        const selectedSeats = safeSumTwoIntegers(adults, children);

        const hasOverlappingSessions = areListingSessionsOverlapping(sessions);

        const submitDisabled =
          !startDate ||
          !endDate ||
          !hasSelectedSessions ||
          !selectedSeats ||
          hasOverlappingSessions;

        // This is the place to collect breakdown estimation data. See the
        // EstimatedBreakdownMaybe component to change the calculations
        // for customized payment processes.
        const bookingData =
          startDate && endDate && hasSelectedSessions
            ? {
                unitType,
                startDate,
                endDate,
                timeZone,
              }
            : null;

        const showEstimatedBreakdown =
          bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

        const estimatedTx = showEstimatedBreakdown
          ? estimatedTransaction(
              startDate,
              endDate,
              lineItems,
              'customer',
              currentUser,
              listingAuthor
            )
          : null;

        const bookingInfoMaybe = showEstimatedBreakdown ? (
          <div className={css.priceBreakdownContainer}>
            <EstimatedBreakdownMaybe
              bookingData={bookingData}
              lineItems={lineItems}
              sessionsDiscount={sessionsDiscount}
              seasonalCharge={seasonalCharge}
              additionalPersonCharge={additionalPersonCharge}
              currentUser={currentUser}
              author={listingAuthor}
            />
          </div>
        ) : null;

        const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
          <IconSpinner className={css.spinner} />
        ) : null;

        const bookingInfoErrorMaybe = fetchLineItemsError ? (
          <span className={css.sideBarError}>
            <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
          </span>
        ) : null;

        const overlappingSessionsError = hasOverlappingSessions ? (
          <span className={css.sideBarError}>
            <FormattedMessage id="BookingDatesForm.overlappingSessionsError" />
          </span>
        ) : null;

        const submitButtonClasses = classNames(
          submitButtonWrapperClassName || css.submitButtonWrapper
        );

        const sessionsForSelectedDates = getSessionsForSelectedDates(startDate, endDate);

        return (
          <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
            <FormSpy subscription={{ values: true }} onChange={handleOnChange} />

            <div className={css.seats}>
              {canShowAdults && (
                <FieldNumberIncrementerInput
                  id="adults"
                  name="adults"
                  label={messages.adults}
                  description={messages.age}
                  fieldClassName={css.seatField}
                  min="0"
                  max={maxPossibleAdults}
                  onChange={() => resetDatesAndSessions(form)}
                />
              )}
              {canShowChildren && (
                <FieldNumberIncrementerInput
                  id="children"
                  name="children"
                  label={messages.children}
                  fieldClassName={css.seatField}
                  min="0"
                  max={maxPossibleChildren}
                  onChange={() => resetDatesAndSessions(form)}
                />
              )}
            </div>
            {timeZone ? (
              <AvailabilityDatesPicker
                intl={intl}
                selectedDates={dates}
                isMobileLayout={isMobileLayout}
                onManageDisableScrolling={onManageDisableScrolling}
                toggleClassName={css.datesToggle}
                timeZone={timeZone}
                monthlyTimeSlots={monthlyTimeSlots}
                selectedSeats={selectedSeats}
                isMultiDay={isMultiDay}
                multiDaySessionStartIndex={multiDaySessionStartIndex}
                duration={duration}
                onDatesChanged={() => form.change('sessions', [])}
                useAvailability
                placeholder={intl.formatMessage({ id: 'BookingTimeForm.selectAvailableDays' })}
              />
            ) : null}

            <FieldSessionSelector
              id="sessions"
              name="sessions"
              options={sessionsForSelectedDates}
              intl={intl}
              startDate={startDate}
              endDate={endDate}
              timeZone={timeZone}
              isMultiDay={isMultiDay}
              sessionsDiscount={sessionsDiscount}
              duration={duration}
              estimatedTx={estimatedTx}
              currency={unitPrice.currency}
              clearSessions={() => form.change('sessions', [])}
              listingAuthor={listingAuthor}
            />

            {bookingInfoMaybe}
            {loadingSpinnerMaybe}
            {bookingInfoErrorMaybe}
            {overlappingSessionsError}

            <div className={submitButtonClasses}>
              {!isFreeListing && (
                <p className={css.smallPrint}>
                  <FormattedMessage id="BookingTimeForm.youWontBeChargedInfo" />
                </p>
              )}
              <PrimaryButton type="submit" disabled={submitDisabled}>
                <FormattedMessage id="General.book" />
              </PrimaryButton>

              {!!onContactUser && (
                <>
                  <h3 className={css.or}>
                    <FormattedMessage id="General.or" />
                  </h3>
                  <SecondaryButton type="button" color="black" onClick={onContactUser}>
                    <FormattedMessage id="General.contact" />
                  </SecondaryButton>
                </>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  onContactUser: func,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(
  withViewport,
  injectIntl
)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
