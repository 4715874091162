import { convertDateToMoment } from './dates';
import { LINE_ITEM_SESSION } from './types';

export const getSessionDatesFromLineItemCode = (code, timeZone) => {
  // +1 because there is a dash (-) after the line item base name
  const dateStrings = code.substring(
    code.indexOf(LINE_ITEM_SESSION) + LINE_ITEM_SESSION.length + 1
  );

  const [startDateString, endDateString] = dateStrings.split('?');

  return {
    startDate: convertDateToMoment(startDateString, timeZone),
    endDate: convertDateToMoment(endDateString, timeZone),
    startDateString,
    endDateString,
  };
};

export const checkIfDatesOverlap = (startDate1, endDate1, startDate2, endDate2) => {
  const startsAtA = new Date(startDate1);
  const endsAtA = new Date(endDate1);
  const startsAtB = new Date(startDate2);
  const endsAtB = new Date(endDate2);

  return startsAtA < endsAtB && startsAtB < endsAtA;
  // Not overlapping if startsAtA >= endsAtB || endsAtA <= startsAtB

  // startsAtA as {
  // endsAtA as }
  // startsAtB as [
  // endsAtB as ]

  // Not overlapping cases
  // -----[-----]--{-}-
  // -{-}-[-----]------

  // Overlapping
  // --{--[---}-]------
  // -----[-{---]--}---
  // --{--[-----]--}---
  // -----[{---}]------
};

export const createEventFromBooking = booking => {
  const { _id, timezone, startsAt, endsAt, listingTitle } = booking;

  return {
    title: listingTitle,
    start: convertDateToMoment(startsAt, timezone).toDate(),
    end: convertDateToMoment(endsAt, timezone).toDate(),
    resource: {
      id: _id,
      bookings: [booking],
    },
  };
};

export const getNormalizedFee = (value, defaultValue) => {
  const normalizedFee = Math.abs(+value);

  if (isNaN(normalizedFee) || normalizedFee > 100) {
    return defaultValue;
  }

  return normalizedFee;
};
