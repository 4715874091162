import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import {
  SearchableMultiSelect,
  Form,
  PrimaryButton,
  Modal,
  AvailabilityDatesPicker,
  FieldLocationAutocompleteInput,
} from '../../../components';
import { findOptionsForSelectFilter } from '../../../util/search';
import { useConfiguration } from '../../../context/configurationContext';
import { autocompletePlaceSelected } from '../../../util/validators';
import { useSelector } from 'react-redux';
import { parse } from '../../../util/urlHelpers';

import suggestedLocations from '../../../assets/suggestedLocations.json';

import css from './TopbarSearchForm.module.css';

const getSelectedActivitiesForDummy = (selectedActivities, translatedActivities) => {
  const dummyActivities = [];

  for (let index = 0; index < translatedActivities.length; index++) {
    const { key, label } = translatedActivities[index];

    if (selectedActivities.includes(key)) dummyActivities.push(label);
  }

  return dummyActivities.join(', ');
};

const TopbarSearchFormComponent = props => {
  const {
    onSubmit,
    intl,
    handleExpandTopbar,
    isTopbarExpanded,
    isMobileLayout,
    searchFormRef,
    isExpandedByDefault,
    isTopbarSticky,
    isInTopbar,
    onManageDisableScrolling,
    isLandingPage,
    isSearchPage,
    hasInitialValues,
    ...restOfProps
  } = props;

  const config = useConfiguration();
  const formApi = useRef();

  const { interfaceLang } = useSelector(state => state.ui);

  const [isMultiSelectFieldModal, setIsMultiSelectFieldModal] = useState(false);
  const [isLocationFieldModal, setIsLocationFieldModal] = useState(false);
  const [isDatesPickerPopupOpen, setIsDatesPickerPopupOpen] = useState(false);

  const activitiesByCountry = useMemo(
    () =>
      suggestedLocations.reduce((locations, location) => {
        const { country } = location['en-US'];

        return { ...locations, [country]: [...(locations[country] || []), location] };
      }, {}),
    []
  );

  const defaultPredictions = useMemo(
    () =>
      Object.values(activitiesByCountry).flatMap(locations => {
        const predictions = locations.map(location => {
          const { name, url } = location[interfaceLang];

          const { origin, bounds } = parse(url, {
            latlng: ['origin'],
            latlngBounds: ['bounds'],
          });

          return {
            id: `default-${name}`,
            predictionPlace: {
              address: name,
              bounds,
              origin,
            },
          };
        });

        const { name } = locations[0][interfaceLang];

        return [
          {
            id: `default-header-${name}`,
            headerCount: locations.length,
            predictionPlace: {
              address: name,
            },
          },
          ...predictions,
        ];
      }),
    [activitiesByCountry, interfaceLang]
  );

  const activitiesAsOptions = useMemo(
    () =>
      findOptionsForSelectFilter('activity', config.search.defaultFilters).map(
        ({ key, label }) => ({
          key,
          label: intl.formatMessage({ id: label }),
        })
      ),
    [config, intl]
  );

  const isMultiSelectInModal = useMemo(() => isMobileLayout && isMultiSelectFieldModal, [
    isMobileLayout,
    isMultiSelectFieldModal,
  ]);

  const locationInput = (
    <FieldLocationAutocompleteInput
      rootClassName={classNames(css.location, { [css.locationInModal]: isLocationFieldModal })}
      inputClassName={classNames(css.locationAutocompleteInput, {
        [css.locationAutocompleteInputInModal]: isLocationFieldModal,
      })}
      iconClassName={css.locationAutocompleteInputIcon}
      predictionsClassName={classNames(css.predictionsRoot, {
        [css.predictionsRootInModal]: isLocationFieldModal,
      })}
      invalidClassName={css.invalidLocation}
      labelClassName={css.locationLabelWrapper}
      className={classNames({ [css.locationInputWrapperInModal]: isLocationFieldModal })}
      id="location"
      name="location"
      label={intl.formatMessage({ id: 'TopbarSearchForm.location' })}
      placeholder={intl.formatMessage({ id: 'TopbarSearchForm.locationPlaceholder' })}
      predictionScopes={['country', 'region', 'district', 'place', 'locality']}
      // useDefaultPredictions={false}
      defaultPredictions={defaultPredictions}
      format={v => v}
      autoFocus={isMobileLayout}
      validate={autocompletePlaceSelected(
        intl.formatMessage({
          id: 'EditListingActivityForm.addressNotRecognized',
        }),
        true,
        true,
        true
      )}
      hideErrorMessage
      showPredictionsPlaceholder
      onChange={place => {
        // Sometimes we have a value inside the place
        const { search, selectedPlace } = (place?.value ? place.value : place) || {};

        if (!search && !selectedPlace) {
          // Wait for autocomplete debounce, max 1s
          setTimeout(() => {
            formApi.current.resetFieldState('location');
          }, 1000);
        }
      }}
    />
  );

  return (
    <FinalForm
      onSubmit={onSubmit}
      {...restOfProps}
      render={formRenderProps => {
        const { rootClassName, className, handleSubmit, values, formId, form } = formRenderProps;

        formApi.current = form;

        const { dates, activities = [], location } = values;

        const isSelectedCurrentLocation =
          !location?.selectedPlace?.address &&
          location?.selectedPlace?.origin &&
          location?.selectedPlace?.bounds;

        const classes = classNames(rootClassName || css.root, className, {
          [css.expandedRoot]: isTopbarExpanded,
          [css.searchPageExpandedRoot]: isSearchPage,
        });

        const selectedActivitiesForDummy = getSelectedActivitiesForDummy(
          activities,
          activitiesAsOptions
        );

        const searchableMultiSelect = (
          <SearchableMultiSelect
            id={formId ? `${formId}.search` : 'search'}
            name="search"
            options={activitiesAsOptions}
            optionsId={formId ? `${formId}.activities` : 'activities'}
            optionsName="activities"
            placeholder={intl.formatMessage({ id: 'TopbarSearchForm.activityPlaceholder' })}
            label={intl.formatMessage({ id: 'TopbarSearchForm.activity' })}
            noSearchFoundText={intl.formatMessage({ id: 'TopbarSearchForm.noActivity' })}
            className={classNames(css.activitySelect, {
              [css.activitySelectInModal]: isMultiSelectInModal,
            })}
            inputContainerClassName={classNames(css.activitySelectInputContainer, {
              [css.activitySelectInputContainerInModal]: isMultiSelectInModal,
            })}
            optionsClassName={
              isMultiSelectInModal ? css.activityOptionsInModal : css.activityOptions
            }
            selectedOptions={activities}
            isAlwaysOpen={isMobileLayout}
            form={form}
            tabIndex={1}
            readOnly
          />
        );

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            enforcePagePreloadFor="SearchPage"
            contentRef={searchFormRef}
          >
            <button
              type="button"
              className={classNames(css.expandButton, {
                [css.hideExpandButton]:
                  isTopbarExpanded || isExpandedByDefault || (isLandingPage && !isTopbarSticky),
              })}
              onClick={handleExpandTopbar}
            >
              {intl.formatMessage({ id: 'General.findActivity' })}
              {hasInitialValues ? <span className={css.selectedValuesBadge} /> : null}
            </button>
            <div
              className={classNames(css.expandableFields, {
                [css.expandableFieldsInTopbar]: isInTopbar,
                [css.fieldsExpanded]: isTopbarExpanded,
                [css.fieldsExpandedByDefault]: isExpandedByDefault,
              })}
            >
              {isMobileLayout ? (
                <Modal
                  id="LocationSelectModal"
                  isOpen={isLocationFieldModal}
                  onClose={() => setIsLocationFieldModal(false)}
                  onManageDisableScrolling={onManageDisableScrolling}
                  contentClassName={css.modalContent}
                  usePortal
                >
                  {isLocationFieldModal && locationInput}
                  <div className={css.modalFooter}>
                    <PrimaryButton
                      type="button"
                      onClick={e => {
                        // Stop propagation so we don't close the topbar if it is extended
                        e.stopPropagation();
                        setIsLocationFieldModal(false);
                      }}
                    >
                      {intl.formatMessage({ id: 'General.save' })}
                    </PrimaryButton>
                  </div>
                </Modal>
              ) : (
                locationInput
              )}

              {isMobileLayout ? (
                <Modal
                  id="SearchableMultiSelectModal"
                  isOpen={isMultiSelectFieldModal}
                  onClose={() => setIsMultiSelectFieldModal(false)}
                  onManageDisableScrolling={onManageDisableScrolling}
                  contentClassName={css.modalContent}
                  usePortal
                >
                  {searchableMultiSelect}
                  <div className={css.modalFooter}>
                    <PrimaryButton
                      type="button"
                      onClick={e => {
                        // Stop propagation so we don't close the topbar if it is extended
                        e.stopPropagation();
                        setIsMultiSelectFieldModal(false);
                      }}
                    >
                      {intl.formatMessage({ id: 'General.save' })}
                    </PrimaryButton>
                  </div>
                </Modal>
              ) : (
                searchableMultiSelect
              )}

              <button
                type="button"
                onClick={() => setIsLocationFieldModal(true)}
                className={css.dummyActivitySelect}
              >
                <label>{intl.formatMessage({ id: 'TopbarSearchForm.location' })}</label>
                <div
                  className={classNames(css.dummyActivitySelectInput, {
                    [css.dummyInputWithValues]: location?.selectedPlace?.address,
                  })}
                >
                  {location?.selectedPlace?.address ||
                    (isSelectedCurrentLocation
                      ? intl.formatMessage({ id: 'General.myLocation' })
                      : intl.formatMessage({ id: 'TopbarSearchForm.locationPlaceholder' }))}
                </div>
              </button>

              <button
                type="button"
                onClick={() => setIsMultiSelectFieldModal(true)}
                className={css.dummyActivitySelect}
              >
                <label>{intl.formatMessage({ id: 'TopbarSearchForm.activity' })}</label>
                <div
                  className={classNames(css.dummyActivitySelectInput, {
                    [css.dummyInputWithValues]: selectedActivitiesForDummy,
                  })}
                >
                  {selectedActivitiesForDummy ||
                    intl.formatMessage({ id: 'TopbarSearchForm.activityPlaceholder' })}
                </div>
              </button>

              <div
                className={classNames(css.datesAndSubmit, {
                  [css.datesPickerOpen]: isDatesPickerPopupOpen,
                })}
                tabIndex="-1"
              >
                <AvailabilityDatesPicker
                  intl={intl}
                  selectedDates={dates}
                  isMobileLayout={isMobileLayout}
                  onManageDisableScrolling={onManageDisableScrolling}
                  toggleClassName={css.datesPickerToggle}
                  formId={formId}
                  displayedDatesClassName={css.displayedDates}
                  onComponentMount={() => setIsDatesPickerPopupOpen(true)}
                  onComponentUnmount={() => setIsDatesPickerPopupOpen(false)}
                />
              </div>
              <div
                className={classNames(css.submitButtonContainer, {
                  [css.submitButtonContainerInTopbar]: isInTopbar,
                })}
              >
                <PrimaryButton
                  type="submit"
                  className={classNames(css.submitButton, {
                    [css.submitButtonInTopbar]: isInTopbar,
                  })}
                >
                  {intl.formatMessage({ id: 'General.search' })}
                </PrimaryButton>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

const { func, string } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  formId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
