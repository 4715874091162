import React, { useEffect, useRef, useState } from 'react';
import { IconClose, InlineTextButton, Logo } from '..';
import { useIntl } from 'react-intl';

import css from './InstallPrompt.module.css';
import { isFirefox, isIOS, isInStandaloneMode, isSamsungInternet } from '../../util/userAgent';

const InstallPrompt = () => {
  const intl = useIntl();
  const [installPrompt, setInstallPrompt] = useState(null);
  const [installForUnsupported, setInstallForUnsupported] = useState(null);

  const hasShownPrompt = useRef(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const addInstallPrompt = async event => {
      event.preventDefault();

      if (hasShownPrompt.current) return;

      setInstallPrompt(event);

      hasShownPrompt.current = true;
    };

    window.addEventListener('beforeinstallprompt', addInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', addInstallPrompt);
    };
  }, []);

  useEffect(() => {
    if (isInStandaloneMode()) return;

    if (isIOS()) {
      setInstallForUnsupported(intl.formatMessage({ id: 'General.addToHomeScreenIOS' }));
    } else if (isSamsungInternet()) {
      setInstallForUnsupported(intl.formatMessage({ id: 'General.addToHomeScreenSamsung' }));
    } else if (isFirefox()) {
      setInstallForUnsupported(intl.formatMessage({ id: 'General.addToHomeScreenFirefox' }));
    }
  }, [intl]);

  return installPrompt || installForUnsupported ? (
    <div className={css.installApp}>
      <Logo format="mobile" />

      {installPrompt ? (
        <InlineTextButton
          type="button"
          onClick={() => {
            installPrompt.prompt();

            setInstallPrompt(null);
          }}
          className={css.installAppTitle}
        >
          {intl.formatMessage({ id: 'General.addToHomeScreen' })}
        </InlineTextButton>
      ) : (
        <div className={css.installAppTitle}>
          {intl.formatMessage({ id: 'General.addToHomeScreen' })}
          <br />
          <div className={css.installNote}>{installForUnsupported}</div>
        </div>
      )}
      <button
        type="button"
        onClick={() => {
          setInstallPrompt(null);
          setInstallForUnsupported(null);
        }}
        className={css.closeButton}
      >
        <IconClose />
      </button>
    </div>
  ) : null;
};

export default InstallPrompt;
