import React from 'react';
import { string } from 'prop-types';

const IconAirSports = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 36 23.04">
      <path
        d="M20.775,20.687a1.8,1.8,0,0,0,2.275-1.071A1.73,1.73,0,0,0,21.945,17.4a1.8,1.8,0,0,0-2.275,1.076,1.727,1.727,0,0,0,1.105,2.21ZM35.425,9.343a180.061,180.061,0,0,0-18.6-.708C7.565,8.635,0,9.343,0,9.343s3.137,1.346,2.593,5.517c0,0,4.5-.523,6.387,3.233l8.455-2.5-.553,5.5-3.576.855a1.84,1.84,0,0,0-.8.411L4.656,29.735a1.1,1.1,0,0,0-.024,1.59,1.176,1.176,0,0,0,1.633.04l5.9-5.54L19.4,24.1l-.049.015,1.2-.3,1.105,1.228a.929.929,0,0,0,.675.292l3.892.015a.884.884,0,0,0,.894-.87.871.871,0,0,0-.7-.84l-2.9-9.836L35.6,10.238c.577-.246.528-.819-.179-.894ZM25.553,23.6c-.886,0-2.365-.008-2.812-.008l-2.1-2.311-.089-.063a1.814,1.814,0,0,0-1.77-.578l-1.049.254.561-5.55,4.428-1.305L25.553,23.6Z"
        transform="translate(0 -8.635)"
      />
    </svg>
  );
};

IconAirSports.defaultProps = { className: null };
IconAirSports.propTypes = { className: string };

export default IconAirSports;
