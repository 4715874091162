import listings from './listings';
import listingBookings from './listing-bookings';
import listingSessions from './listing-sessions';
import transactions from './transactions';
import users from './users';
import contactUs from './contact-us';
import images from './images';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  listings,
  listingBookings,
  listingSessions,
  users,
  transactions,
  contactUs,
  images,
};
