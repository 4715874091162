import React from 'react';
import classNames from 'classnames';
import { arrayOf, object, string } from 'prop-types';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './BookedByPreview.module.css';

const BookedByPreview = props => {
  const { className, bookings } = props;

  return (
    <ul className={classNames(css.root, className)}>
      {bookings.map((booking, i) => {
        const { _id, customerName, customerImage, seats, transactionId, status } = booking;

        const name = customerName || <FormattedMessage id="General.deletedUser" />;

        const { url } = customerImage?.attributes?.variants?.default || {};

        const isPending = status === 'PENDING';
        const isCancelled = status === 'CANCELLED';

        return (
          <li key={_id + i}>
            <NamedLink
              name="SaleDetailsPage"
              params={{ id: transactionId }}
              className={classNames(css.bookingLink, {
                [css.bookingRequested]: isPending,
                [css.bookingCancelled]: isCancelled,
              })}
            >
              {url && <img alt={customerName || ''} className={css.customerAvatar} src={url} />}
              <span className={css.customerName}>{name}</span>

              <span className={css.seats}>x{seats}</span>
            </NamedLink>
          </li>
        );
      })}
    </ul>
  );
};

BookedByPreview.defaultProps = {
  bookings: [],
};

BookedByPreview.propTypes = {
  className: string,
  bookings: arrayOf(object),
};

export default BookedByPreview;
