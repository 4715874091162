import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { FieldDateRangeController, Modal, PrimaryButton } from '..';
import { convertDateToMoment, formatDateRange } from '../../util/dates';
import { intlShape } from '../../util/reactIntl';
import { bool, func, instanceOf, shape, string } from 'prop-types';
import FilterPopup from '../../containers/SearchPage/FilterPopup/FilterPopup';

import css from './AvailabilityDatesPicker.module.css';

const TODAY = convertDateToMoment();

const POPUP_ID = 'TopbarSearchDatesPopup';

const AvailabilityDatesPicker = props => {
  const {
    intl,
    toggleClassName,
    selectedDates,
    isMobileLayout,
    formId,
    onManageDisableScrolling,
    displayedDatesClassName,
    setPopupVisibility,
    onComponentMount,
    onComponentUnmount,
    placeholder,
    ...restOfProps
  } = props;

  const [isDatePickerFieldModal, setIsDatePickerFieldModal] = useState(false);

  const formattedSelectedDates = useMemo(() => formatDateRange(intl, selectedDates), [
    intl,
    selectedDates,
  ]);

  const datePickerProps = useMemo(
    () => ({
      orientation: isMobileLayout ? 'verticalScrollable' : 'horizontal',
      numberOfMonths: isMobileLayout ? 3 : 2,
    }),
    [isMobileLayout]
  );

  const datesToDisplay = useMemo(
    () =>
      formattedSelectedDates ||
      placeholder ||
      intl.formatMessage({ id: 'General.datePlaceholder' }),
    [formattedSelectedDates, intl, placeholder]
  );

  const datesPickerButtonContent = useMemo(
    () => (
      <>
        <label> {intl.formatMessage({ id: 'General.date' })}</label>
        <span className={displayedDatesClassName}> {datesToDisplay}</span>
      </>
    ),
    [displayedDatesClassName, datesToDisplay, intl]
  );

  const datePicker = useMemo(
    () => (
      <FieldDateRangeController
        id={formId ? `${formId}.dates` : 'dates'}
        name="dates"
        className={css.datesPicker}
        onComponentMount={onComponentMount}
        onComponentUnmount={onComponentUnmount}
        noBorder
        minDate={TODAY}
        showCalendarFooter={!isMobileLayout}
        popupId={POPUP_ID}
        {...datePickerProps}
        {...restOfProps}
      />
    ),
    [formId, onComponentMount, onComponentUnmount, isMobileLayout, datePickerProps, restOfProps]
  );

  return (
    <>
      <button
        type="button"
        onClick={() => setIsDatePickerFieldModal(true)}
        className={classNames(css.datesToggle, toggleClassName)}
      >
        <label>{intl.formatMessage({ id: 'General.date' })}</label>
        <div
          className={classNames(css.datesToggleContent, {
            [css.datesToggleContentWithValue]: formattedSelectedDates,
          })}
        >
          <span className={displayedDatesClassName}>{datesToDisplay}</span>
        </div>
      </button>

      {isMobileLayout ? (
        <Modal
          id="DatesPickerModal"
          isOpen={isDatePickerFieldModal}
          onClose={() => setIsDatePickerFieldModal(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          contentClassName={css.modalContent}
          usePortal
        >
          {datePicker}
          <div className={css.modalFooter}>
            <PrimaryButton
              type="button"
              onClick={e => {
                // Stop propagation so we don't close the topbar if it is extended
                e.stopPropagation();
                setIsDatePickerFieldModal(false);
              }}
              disabled={!formattedSelectedDates}
            >
              {intl.formatMessage({ id: 'General.save' })}
            </PrimaryButton>
          </div>
        </Modal>
      ) : (
        <FilterPopup
          className={css.filterPopup}
          clickHandlerClassName={css.filterPopupClickHandler}
          labelClassName={classNames(
            css.datesPickerButton,
            {
              [css.datesPickerWithDatesSelected]: !!formattedSelectedDates,
            },
            toggleClassName
          )}
          labelOpenClassName={css.datesPickerButtonSelected}
          label={datesPickerButtonContent}
          id={POPUP_ID}
          showAsPopup
          useForm={false}
          forceRenderDirection="left"
          listenForBusToggleOpen
        >
          {datePicker}
        </FilterPopup>
      )}
    </>
  );
};

AvailabilityDatesPicker.propTypes = {
  intl: intlShape.isRequired,
  isMobileLayout: bool.isRequired,
  formId: string,
  onManageDisableScrolling: func.isRequired,
  selectedDates: shape({ startDate: instanceOf(Date), endDate: instanceOf(Date) }),
  displayedDatesClassName: string,
  toggleClassName: string,
  useAvailability: bool,
};

export default AvailabilityDatesPicker;
