import React, { useCallback, useMemo } from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { convertNumberToMoney, formatMoney } from '../../util/currency';
import { intlShape } from '../../util/reactIntl';
import IconCheckmark from '../IconCheckmark/IconCheckmark';
import { convertDateToMoment } from '../../util/dates';
import { useConfiguration } from '../../context/configurationContext';
import { getNormalizedFee } from '../../util/sessions';
import { CUSTOMER_FEE_PERCENTAGE } from '../../transactions/transactionsUtil';
import { useSelector } from 'react-redux';

import css from './FieldSessionItem.module.css';

const FieldSessionItemComponent = props => {
  const config = useConfiguration();

  const {
    intl,
    currency = config.currency,
    className,
    inputClassName,
    labelClassName,
    id,
    onChange,
    input,
    duration,
    listingAuthor,
    ...rest
  } = props;

  const { currentUser } = useSelector(state => state.user);

  const { name, value, onBlur, onChange: inputOnChange, checked } = input;

  const classes = useMemo(() => classNames(css.root, className), [className]);

  const { startsAt, endsAt, price: sessionPrice, savings } = useMemo(
    () => (value ? JSON.parse(value) : {}),
    [value]
  );

  const isCurrentUserAuthor = currentUser?.id?.uuid === listingAuthor?.id?.uuid;

  const buyerFee = isCurrentUserAuthor
    ? CUSTOMER_FEE_PERCENTAGE
    : currentUser?.attributes?.profile?.metadata?.customerFee;

  const feeAmount = sessionPrice * (getNormalizedFee(buyerFee, CUSTOMER_FEE_PERCENTAGE) / 100);

  const price = sessionPrice + feeAmount;

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = useCallback(
    event => {
      inputOnChange(event);
      onChange?.({ name, value, checked: event.target.checked });

      onBlur(event);
    },
    [inputOnChange, name, onBlur, onChange, value]
  );

  const inputProps = useMemo(
    () => ({
      id,
      className: classNames(css.input, inputClassName),
      onChange: handleOnChange,
      ...input,
      ...rest,
    }),
    [id, inputClassName, handleOnChange, input, rest]
  );

  const startTime = startsAt ? convertDateToMoment(startsAt).format('HH:mm') : '-';
  const endTime = endsAt ? convertDateToMoment(endsAt).format('HH:mm') : '-';

  return (
    <span className={classes}>
      <input {...inputProps} />
      <label htmlFor={id} className={classNames(css.label, labelClassName)}>
        <span>
          {startTime} - {endTime}
        </span>
        {!!price && (
          <span className={css.price}>
            {formatMoney(intl, convertNumberToMoney(price * duration, currency))}
          </span>
        )}

        <div className={css.rightSide}>
          {checked ? (
            <IconCheckmark className={css.checkmark} />
          ) : (
            savings && <div className={css.savings}> {savings}</div>
          )}
        </div>
      </label>
    </span>
  );
};

FieldSessionItemComponent.defaultProps = {
  className: null,
  inputClassName: null,
  labelClassName: null,
  label: null,
};

FieldSessionItemComponent.propTypes = {
  className: string,
  inputClassName: string,
  labelClassName: string,
  intl: intlShape.isRequired,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,
};

const FieldSessionItem = props => {
  return <Field component={FieldSessionItemComponent} type="checkbox" {...props} />;
};

export default FieldSessionItem;
