/**
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React from 'react';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../../util/sdkLoader';
import {
  unitDivisor,
  convertMoneyToNumber,
  convertUnitToSubUnit,
  convertNumberToMoney,
} from '../../../util/currency';
import { OrderBreakdown } from '../..';
import defaultConfig from '../../../config/configDefault';

import css from './BookingTimeForm.module.css';
import { transitions } from '../../../transactions/transactionProcessBooking';
import { TX_TRANSITION_ACTOR_CUSTOMER } from '../../../transactions/transaction';
import { getNormalizedFee } from '../../../util/sessions';
import { CUSTOMER_FEE_PERCENTAGE } from '../../../transactions/transactionsUtil';
import { LINE_ITEM_SESSION } from '../../../util/types';

const { Money, UUID } = sdkTypes;

const estimatedTotalPrice = lineItems => {
  const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
    const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
    return new Decimal(numericPrice).add(sum);
  }, 0);

  // All the lineItems should have same currency so we can use the first one to check that
  // In case there are no lineItems we use currency from config.js as default
  const currency =
    lineItems[0] && lineItems[0].unitPrice
      ? lineItems[0].unitPrice.currency
      : defaultConfig.currency;

  return new Money(
    convertUnitToSubUnit(numericTotalPrice.toNumber(), unitDivisor(currency)),
    currency
  );
};

export const estimatePayinTotal = lineItems =>
  estimatedTotalPrice(lineItems.filter(item => item.includeFor.includes('customer')));

const estimatedCustomerFeeAmount = (price, currentUser, author) => {
  const isCurrentUserAuthor = currentUser?.id?.uuid === author?.id?.uuid;

  const buyerFee = isCurrentUserAuthor
    ? CUSTOMER_FEE_PERCENTAGE
    : currentUser?.attributes?.profile?.metadata?.customerFee;

  const feeAmount = price?.amount * (getNormalizedFee(buyerFee, CUSTOMER_FEE_PERCENTAGE) / 100);

  return feeAmount || 0;
};

// When we cannot speculatively initiate a transaction (i.e. logged
// out), we must estimate the transaction for booking breakdown. This function creates
// an estimated transaction object for that use case.
//
// We need to use FTW backend to calculate the correct line items through thransactionLineItems
// endpoint so that they can be passed to this estimated transaction.
export const estimatedTransaction = (
  bookingStart,
  bookingEnd,
  lineItems,
  userRole,
  currentUser,
  author
) => {
  const now = new Date();

  const isCustomer = userRole === 'customer';

  const cLineItems = lineItems.filter(item => item.includeFor.includes('customer'));
  const providerLineItems = lineItems.filter(item => item.includeFor.includes('provider'));

  const payinTotal = estimatedTotalPrice(cLineItems);
  const payoutTotal = estimatedTotalPrice(providerLineItems);

  const customerLineItems = cLineItems.map(lineItem => {
    if (lineItem.code.startsWith(LINE_ITEM_SESSION)) {
      const customerFee = estimatedCustomerFeeAmount(lineItem.lineTotal, currentUser, author);

      return {
        ...lineItem,
        lineTotal: convertNumberToMoney(
          lineItem.lineTotal.amount + customerFee,
          lineItem.lineTotal.currency
        ),
      };
    }

    return lineItem;
  });

  return {
    id: new UUID('estimated-transaction'),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: transitions.REQUEST_PAYMENT,
      payinTotal,
      payoutTotal,
      lineItems: isCustomer ? customerLineItems : providerLineItems,
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: transitions.REQUEST_PAYMENT,
        },
      ],
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: bookingStart,
        end: bookingEnd,
      },
    },
  };
};

const EstimatedBreakdownMaybe = props => {
  const {
    bookingData,
    lineItems,
    additionalPersonCharge,
    sessionsDiscount,
    seasonalCharge,
    currentUser,
    author,
  } = props;

  const { unitType, startDate, endDate, timeZone } = bookingData;

  // Currently the estimated breakdown is used only on ListingPage where we want to
  // show the breakdown for customer so we can use hard-coded value here
  const userRole = 'customer';

  const tx =
    startDate && endDate && lineItems
      ? estimatedTransaction(startDate, endDate, lineItems, userRole, currentUser, author)
      : null;

  return tx ? (
    <OrderBreakdown
      className={css.receipt}
      userRole={userRole}
      unitType={unitType}
      transaction={tx}
      booking={tx.booking}
      timeZone={timeZone}
      sessionsDiscount={sessionsDiscount}
      seasonalCharge={seasonalCharge}
      additionalPersonCharge={additionalPersonCharge}
      isEstimated
    />
  ) : null;
};

export default EstimatedBreakdownMaybe;
