import React, { useMemo, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  LanguageSelect,
} from '../../components';
import IconSocialMediaLinkedIn from '../IconSocialMediaLinkedIn/IconSocialMediaLinkedIn';
import ModalContainer from '../ModalContainer/ModalContainer';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import css from './Footer.module.css';
import { useConfiguration } from '../../context/configurationContext';
import defaultLocations from '../../config/configDefaultLocationSearches';
import IconSwistainableLogo from '../SectionPartners/logos/IconSwistainableLogo';
import { useSelector } from 'react-redux';

const MODAL_TYPE = {
  CHANGE_LANGUAGE: 'changeLanguage',
};

const renderSocialMediaLinks = (intl, config) => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteLinkedinPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={intl.formatMessage({ id: 'Footer.goToTwitter' })}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const facebookLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={css.icon}
      title={intl.formatMessage({ id: 'Footer.goToFacebook' })}
    >
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={intl.formatMessage({ id: 'Footer.goToInstagram' })}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink
      key="linkToLinkedin"
      href={siteLinkedinPage}
      className={css.icon}
      title={intl.formatMessage({ id: 'Footer.goToLinkedin' })}
    >
      <IconSocialMediaLinkedIn />
    </ExternalLink>
  ) : null;

  return [twitterLink, facebookLink, instragramLink, linkedinLink].filter(Boolean);
};

const Footer = props => {
  const { rootClassName, className, location, intl } = props;

  const config = useConfiguration();

  const { interfaceLang } = useSelector(state => state.ui);

  const [modalTypeOpen, setModalTypeOpen] = useState(null);

  const socialMediaLinks = useMemo(() => renderSocialMediaLinks(intl, config), [intl, config]);

  const langWithoutLocale = interfaceLang?.split('-')?.[0] || 'en';

  const classes = useMemo(() => classNames(rootClassName || css.root, className), [
    className,
    rootClassName,
  ]);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.logoWrapper} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="mobile" className={css.logo} />
              </NamedLink>
            </div>
            <div className={css.linksWrapper}>
              <div className={css.organizationInfo}>
                <div className={css.infoWrapper}>
                  <p className={css.organizationDescription}>
                    <FormattedMessage id="Footer.organizationDescription" />
                  </p>
                  <p className={css.organizationCopyright}>
                    <NamedLink name="LandingPage" className={css.copyrightLink}>
                      <FormattedMessage id="Footer.copyright" />
                    </NamedLink>
                  </p>
                </div>
              </div>
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="NewListingPage" className={css.link}>
                      <FormattedMessage id="General.addActivity" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="SearchPage"
                      to={{ search: defaultLocations[0].search }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.toBrowseAnActivityPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: langWithoutLocale + '-stories' }}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.blogs" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.searches}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink name="HelpCenterBasePage" className={css.link}>
                      <FormattedMessage id="General.help" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="ContactPage" className={css.link}>
                      <FormattedMessage id="Footer.toContactPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <button
                      className={css.buttonAsLink}
                      onClick={() => setModalTypeOpen(MODAL_TYPE.CHANGE_LANGUAGE)}
                    >
                      <FormattedMessage id="Footer.changeLanguage" />
                    </button>
                  </li>
                </ul>
              </div>
              <ExternalLink
                href="https://www.stnet.ch/de/swisstainable/"
                className={css.partnerLogo}
              >
                <IconSwistainableLogo />
              </ExternalLink>
              <div className={css.extraLinks}>
                <ul className={css.tosAndPrivacy}>
                  {socialMediaLinks}
                  <li>
                    <NamedLink name="TermsAndConditionsPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalContainer
        id="ChangeLanguageCurrencyModal"
        title={!!modalTypeOpen && intl.formatMessage({ id: `Footer.${modalTypeOpen}` })}
        isModalOpen={!!modalTypeOpen}
        onClose={() => setModalTypeOpen(null)}
      >
        {modalTypeOpen === MODAL_TYPE.CHANGE_LANGUAGE && (
          <LanguageSelect location={location} embedded />
        )}
      </ModalContainer>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(Footer);
