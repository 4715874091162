import listingBookings from '../api/listing-bookings';
import listingSessions from '../api/listing-sessions';
import { convertDateToMoment } from '../util/dates';

// ================ Action types ================ //

const FETCH_BOOKINGS_REQUEST = 'app/Bookings/FETCH_BOOKINGS_REQUEST';
const FETCH_BOOKINGS_SUCCESS = 'app/Bookings/FETCH_BOOKINGS_SUCCESS';
const FETCH_BOOKINGS_ERROR = 'app/Bookings/FETCH_BOOKINGS_ERROR';

const FETCH_SESSIONS_REQUEST = 'app/Bookings/FETCH_SESSIONS_REQUEST';
const FETCH_SESSIONS_SUCCESS = 'app/Bookings/FETCH_SESSIONS_SUCCESS';
const FETCH_SESSIONS_ERROR = 'app/Bookings/FETCH_SESSIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  bookings: [],
  fetchBookingsInProgress: false,
  fetchBookingsError: null,

  sessions: [],
  fetchSessionsInProgress: false,
  fetchSessionsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BOOKINGS_REQUEST:
      return {
        ...state,
        bookings: [],
        fetchBookingsInProgress: true,
        fetchBookingsError: null,
      };
    case FETCH_BOOKINGS_SUCCESS:
      return { ...state, fetchBookingsInProgress: false, bookings: payload };
    case FETCH_BOOKINGS_ERROR:
      return { ...state, fetchBookingsInProgress: false, fetchBookingsError: payload };

    case FETCH_SESSIONS_REQUEST:
      return { ...state, sessions: [], fetchSessionsInProgress: true, fetchSessionsError: null };
    case FETCH_SESSIONS_SUCCESS:
      return { ...state, fetchSessionsInProgress: false, sessions: payload };
    case FETCH_SESSIONS_ERROR:
      return { ...state, fetchSessionsInProgress: false, fetchSessionsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchBookingsRequest = () => ({ type: FETCH_BOOKINGS_REQUEST });
export const fetchBookingsSuccess = payload => ({ type: FETCH_BOOKINGS_SUCCESS, payload });
export const fetchBookingsError = error => ({
  type: FETCH_BOOKINGS_ERROR,
  payload: error,
  error: true,
});

const fetchSessionsRequest = () => ({
  type: FETCH_SESSIONS_REQUEST,
});
const fetchSessionsSuccess = payload => ({
  type: FETCH_SESSIONS_SUCCESS,
  payload,
});
const fetchSessionsError = error => ({
  type: FETCH_SESSIONS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchBookings = (listingId, start, end, statuses) => (dispatch, getState, sdk) => {
  dispatch(fetchBookingsRequest());

  const params = {
    listingId: listingId?.uuid,
    startsAt: {
      $lt: convertDateToMoment(end).format(),
    },
    endsAt: {
      $gt: convertDateToMoment(start).format(),
    },
    status: {
      $in: statuses || ['PENDING', 'ACCEPTED'],
    },
  };

  return listingBookings
    .fetchBookings(params)
    .then(res => {
      dispatch(fetchBookingsSuccess(res.data.bookings));
    })
    .catch(e => {
      dispatch(fetchBookingsError(e));
    });
};

export const fetchSessions = (listingId, start, end, seats = 1) => dispatch => {
  dispatch(fetchSessionsRequest());

  const params = {
    startsAt: {
      $lt: convertDateToMoment(end).format(),
    },
    endsAt: {
      $gt: convertDateToMoment(start).format(),
    },
    $sort: 'startsAt',
    availableSeats: { $gte: seats },
    status: 'AVAILABLE',
    closedBySessionIds: {
      $size: 0,
    },
  };

  return listingSessions
    .fetchListingSessions(listingId?.uuid, params)
    .then(response => {
      const { listingSessions = [] } = response?.data || {};

      dispatch(fetchSessionsSuccess(listingSessions));

      return listingSessions;
    })
    .catch(e => {
      dispatch(fetchSessionsError(e));

      throw e;
    });
};
