import React, { useCallback, useEffect, useMemo } from 'react';
import { bool, func, object, shape } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { Page, LayoutSingleColumn } from '../../components';
import { parseSearchFormValuesToParams } from '../../util/search';
import { withViewport } from '../../util/uiHelpers';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import TopbarSearchForm from '../../components/Topbar/TopbarSearchForm/TopbarSearchForm';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import defaultLocations from '../../config/configDefaultLocationSearches';

import css from './NotFoundPage.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const NotFoundPageComponent = props => {
  const { history, viewport, intl, scrollingDisabled, onManageDisableScrolling } = props;

  const isMobileLayout = useMemo(() => viewport.width < MAX_MOBILE_SCREEN_WIDTH, [viewport.width]);

  const routeConfiguration = useRouteConfiguration();

  const messages = useMemo(
    () => ({
      title: intl.formatMessage({ id: 'NotFoundPage.title' }),
      heading: intl.formatMessage({ id: 'NotFoundPage.heading' }),
      description: intl.formatMessage({ id: 'NotFoundPage.description' }),
    }),
    [intl]
  );

  const handleSubmit = useCallback(
    formValues => {
      const formValuesAsParams = parseSearchFormValuesToParams(formValues);
      const location = defaultLocations[0].predictionPlace;
      const searchParams = { ...location, ...formValuesAsParams };

      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    },
    [history, routeConfiguration]
  );

  useEffect(() => {
    // The StaticRouter component used in server side rendering
    // provides the context object. We attach a `notfound` flag to
    // the context to tell the server to change the response status
    // code into a 404.
    props.staticContext.notfound = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title={messages.title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <div className={css.root}>
          <div className={css.content}>
            <div className={css.number}>404</div>
            <h1 className={css.heading}>{messages.heading}</h1>
            <p className={css.description}>{messages.description}</p>
            <TopbarSearchForm
              formId="NotFoundPageSearchForm"
              className={css.searchForm}
              onManageDisableScrolling={onManageDisableScrolling}
              onSubmit={handleSubmit}
              isMobileLayout={isMobileLayout}
              isExpandedByDefault
            />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

NotFoundPageComponent.defaultProps = {
  staticContext: {},
};

NotFoundPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // context object from StaticRouter, injected by the withRouter wrapper
  staticContext: object,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  scrollingDisabled: isScrollingDisabled(state),
});

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const NotFoundPage = compose(
  withViewport,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(NotFoundPageComponent);

export default NotFoundPage;
